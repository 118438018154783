import { createAsyncThunk } from "@reduxjs/toolkit"
import { ThunkConfig } from "../store"
import { notifyApiError } from "../slices/notification"
import {
  InitiateDealOnboardingParams,
  InitiateDealOnboardingSuccess
} from "../../server/controllers/api/deal/initiateOnboardingController"
import { selectDealId } from "../slices/deal"

export const initiateDealOnboarding = createAsyncThunk<
  InitiateDealOnboardingSuccess,
  { email: string; customerName: string; phoneNumber: string },
  ThunkConfig
>(
  "deal/initiateOnboarding",
  async (
    { email, customerName, phoneNumber },
    { dispatch, getState, rejectWithValue, extra }
  ) => {
    const { api } = extra()
    const dealId = selectDealId(getState())

    const result = await api.post<
      InitiateDealOnboardingParams,
      InitiateDealOnboardingSuccess
    >(`/api/deal/${dealId}/onboard`, { email, customerName, phoneNumber })

    if (api.isError(result)) {
      dispatch(notifyApiError(result.error))
      return rejectWithValue(result.error)
    }

    return result.data
  }
)
