const EventQueue = require("./EventQueue")
const Debouncer = require("./Debouncer")

const DEBOUNCE_MS = 1000
const BATCH_LIMIT_MS = 8000
const MAX_BATCH_COUNT = 20
const REQ_TIMEOUT_MS = 5000
const MAX_FAILS = 3
const CAPACITY = 50

/**
 * takes events out of a queue
 * and sends them via HTTP request
 */
function EventSaver({
  url,
  headers,
  logger = console,
  requestTimeoutMs = REQ_TIMEOUT_MS,
  maxBatchCount = MAX_BATCH_COUNT,
  batchLimitMs = BATCH_LIMIT_MS,
  debounceMs = DEBOUNCE_MS,
  capacity = CAPACITY,
  queue = new EventQueue({ capacity })
}) {
  const debouncer = new Debouncer({
    debounceMs,
    limitMs: batchLimitMs,
    maxCalls: maxBatchCount,
    logger
  })
  let isRequestInFlight = false
  let requestTimeout = null
  let requestFailsCount = 0

  window.addEventListener("online", () => attemptBatchSave())

  attemptBatchSave()

  return Object.freeze({ save })

  function save(...events) {
    queue.enqueue(...events)
    // attemptBatchSave is called again once the request completes
    if (!isRequestInFlight) {
      attemptBatchSave()
    }
  }

  function attemptBatchSave() {
    if (queue.size() === 0) return

    if (!window.navigator.onLine) {
      logger.debug(
        "[EventSaver]: No connection. Not trying to save client events."
      )
      return
    }

    function attemptSend() {
      const events = queue.flush()
      const sentAt = new Date().toISOString()
      const timestampedEvents = events.map((event) =>
        addSentAtTimestamp(event, sentAt)
      )

      sendRequest(timestampedEvents)
        .then(() => {
          requestFailsCount = 0
          logger.debug(
            `[EventSaver]: Successfully saved ${timestampedEvents.length} client events`
          )
        })
        .catch((err) => {
          queue.enqueue(...events)
          logger.error(err)
        })
        .finally(() => {
          if (requestFailsCount >= MAX_FAILS) {
            logger.warn(
              `[EventSaver]: request has failed ${MAX_FAILS} times in a row. Stopping further requests for now.`
            )
            requestFailsCount = 0
            return
          }
          attemptBatchSave()
        })
    }

    if (queue.size() >= maxBatchCount) {
      debouncer.cancel()
      attemptSend()
    } else {
      debouncer.debounce(() => attemptSend())
    }
  }

  function sendRequest(events) {
    return new Promise((resolve, reject) => {
      isRequestInFlight = true

      const xhr = new window.XMLHttpRequest()
      xhr.open("POST", url, true)
      xhr.withCredentials = true
      xhr.setRequestHeader("Content-Type", "application/json")

      const _headers = resolveHeaders(headers)
      for (const key in _headers) {
        xhr.setRequestHeader(key, _headers[key])
      }

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status >= 200 && xhr.status < 400) {
          resolve(xhr.response)
        } else if (xhr.readyState === 4 && xhr.status >= 400) {
          requestFailsCount += 1
          const body = deserialize(xhr.response)
          reject(new Error(body ? body.message : "Unknown error"))
        }
        isRequestInFlight = false
        if (requestTimeout) {
          clearTimeout(requestTimeout)
          requestTimeout = null
        }
      }

      requestTimeout = setTimeout(() => {
        xhr.abort("Timed out")
        isRequestInFlight = false
        requestTimeout = null
      }, requestTimeoutMs)

      xhr.send(serialize({ events }))
    })
  }

  function resolveHeaders(headers) {
    if (typeof headers === "function") return headers()
    return headers
  }

  function serialize(body) {
    try {
      return JSON.stringify(body)
    } catch (err) {
      logger.error(err)
      return null
    }
  }

  function deserialize(body) {
    try {
      return JSON.parse(body)
    } catch (err) {
      logger.error(err)
      return null
    }
  }
}

function addSentAtTimestamp(event, sentAt) {
  return {
    ...event,
    sentAt,
  }
}

module.exports = EventSaver
