import Image from "next/image"
import { Box } from "@mui/material"
import SmileeLogo from "../public/smilee.png"
import { useTranslation } from "react-i18next"

function Footer() {
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
      }}
    >
      {t("common:powered_by")}{" "}
      <Image
        src={SmileeLogo}
        alt="Smilee Logo"
        width={88}
        height={45}
        style={{ objectFit: "contain" }}
      />
    </Box>
  )
}

export default Footer
