import { Workbox } from "workbox-window"

let workbox: Workbox | undefined

if (typeof window !== "undefined" && "serviceWorker" in navigator) {
  // NEXT_PUBLIC_BASE_PATH environment variable is only required for development environment
  const swPath = process.env.NEXT_PUBLIC_BASE_PATH
    ? `${process.env.NEXT_PUBLIC_BASE_PATH}/sw.js`
    : "/sw.js"

  const swScope = process.env.NEXT_PUBLIC_BASE_PATH
    ? `${process.env.NEXT_PUBLIC_BASE_PATH}/`
    : "/"

  workbox = new Workbox(window.location.origin + swPath, {
    scope: swScope
  })
}

export { workbox }
