/**
 * Usage:
 *  teams.sort(sortAscWith(team => team.name))
 */
function sortAscWith(getValue) {
  return createSortWith(getValue, true)
}

exports.sortAscWith = sortAscWith

/**
 * Usage:
 *  teams.sortDescWith(team => team.name)
 */
function sortDescWith(getValue) {
  return createSortWith(getValue, false)
}

exports.sortDescWith = sortDescWith

/**
 * Usage:
 *  numbers.sort(sortAsc)
 *  dates.sort(sortAsc)
 */
exports.sortAsc = createSortWith(identity, true)
/**
 * Usage:
 *  numbers.sort(sortAsc)
 *  dates.sort(sortAsc)
 */
exports.sortDesc = createSortWith(identity, false)

/**
 * @returns {(a: any, b: any) => 0 | 1 | -1}
 */
function createSortWith(getValue, asc = true) {
  return (a, b) => {
    const aVal = getValue(a)
    const bVal = getValue(b)
    if (aVal > bVal) return asc ? 1 : -1
    if (aVal < bVal) return asc ? -1 : 1
    if (aVal === bVal) return 0
  }
}

exports.createSortWith = createSortWith

function identity(value) {
  return value
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((element, index) => [element, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map(element => element[0])
}

exports.stableSort = stableSort
