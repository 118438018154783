import EventSaver from "@shared/client-events/EventSaver"
import ClientEventRecorder from "@shared/client-events/ClientEventRecorder"
import resolution from "@shared/utils/resolution"
import { isBrowser } from "../src/utils"

/**
 * This is only for the browser
 */
let clientEventRecorder: ReturnType<typeof ClientEventRecorder>

export function getClientEventRecorder(baseUrl: string) {
  if (isBrowser && !clientEventRecorder) {
    clientEventRecorder = ClientEventRecorder(
      EventSaver({
        url: `${baseUrl}/api/client_events`,
        logger: console,
        headers: {}
      }),
      {
        client: "shoppingcart",
        platform: "web",
        version:
          process.env.NODE_ENV === "development" ? "dev" : process.env.VERSION,
        logger: console,
        createExtras: () => ({
          pageTitle: window.document.title,
          pageUrl: window.location.href,
          browserLang: window.navigator.language,
          screenRes: resolution(window.screen.width, window.screen.height),
          windowRes: resolution(window.innerWidth, window.innerHeight)
        })
      }
    )
  }

  return clientEventRecorder
}
