import {
  // createAppEvent,
  createUserEvent
} from "@shared/client-events/event-creators"

import { TradeInCarImageName } from "../store/slices/tradeinCar"

export function sendSmsLinkClicked() {
  return createUserEvent("send_sms_link:click")
}

export function chatTypingStarted(dealId: string) {
  return createUserEvent("chat:typing_started", { dealId })
}

export function chatMessageSent(dealId: string) {
  return createUserEvent("chat:message_sent", { dealId })
}

export function consentAccepted() {
  return createUserEvent("consent:accepted")
}

export function consentDeclined() {
  return createUserEvent("consent:declined")
}

export function financingApplyLTRClicked(dealId: string) {
  return createUserEvent("financing:apply_ltr_click", { dealId })
}

export function financingSelectionChanged(value: string, dealId: string) {
  return createUserEvent(`financing:selection_changed`, { value, dealId })
}

export function tradeinStatusChanged(value: string, dealId: string) {
  return createUserEvent(`tradein:status_changed`, { value, dealId })
}

export function tradeinUpdatedWindshieldCondition(
  value: string,
  dealId?: string
) {
  return createUserEvent("tradein:updated_windshield_condition", {
    value,
    dealId
  })
}

export function tradeinUpdatedLoanType(value: string, dealId?: string) {
  return createUserEvent("tradein:updated_loan_type", { value, dealId })
}

export function tradeinUpdatedLoanAmount(dealId?: string) {
  return createUserEvent("tradein:updated_loan_amount", { dealId })
}

export function tradeinUpdatedSummerTiresCondition(
  condition: string,
  dealId?: string
) {
  return createUserEvent("tradein:updated_summer_tires_condition", {
    condition,
    dealId
  })
}

export function tradeinUpdatedWinterTiresCondition(
  condition: string,
  dealId?: string
) {
  return createUserEvent("tradein:updated_winter_tires_condition", {
    condition,
    dealId
  })
}

export function tradeinUpdatedMileage(dealId?: string) {
  return createUserEvent("tradein:updated_mileage", { dealId })
}

export function tradeinUpdatedLicensePlate(dealId?: string) {
  return createUserEvent("tradein:updated_license_plate", { dealId })
}

export function tradeinUpdatedAdditionalInfo(dealId?: string) {
  return createUserEvent("tradein:updated_additional_info", { dealId })
}

export function tradeinUploadedCarImage(
  image_name: TradeInCarImageName,
  dealId?: string
) {
  return createUserEvent("tradein:uploaded_car_image", { image_name, dealId })
}

export function tradeinRemovedCarImage(
  image_name: TradeInCarImageName,
  dealId?: string
) {
  return createUserEvent("tradein:removed_car_image", { image_name, dealId })
}

export function loginClicked(location: string, dealId?: string) {
  return createUserEvent("login:click", { location, dealId })
}

export function logoutClicked() {
  return createUserEvent("logout:click")
}

export function rejectLoginClicked() {
  return createUserEvent("reject_login:click")
}

export function offerAccepted(dealId: string) {
  return createUserEvent("offer:accepted", { dealId })
}

export function offerDeclined(dealId: string) {
  return createUserEvent("offer:declined", { dealId })
}

export function offerViewed(dealId: string) {
  return createUserEvent("offer:viewed", { dealId })
}

// removed 05.04.2024
export function welcomeChooseDealTypeClicked(type: string, dealId: string) {
  return createUserEvent("welcome:choose_deal_type_click", { type, dealId })
}

export function welcomePersonalInfoSubmitted(dealId: string) {
  return createUserEvent("welcome:personal_info_submitted", { dealId })
}

export function welcomeTradeinDoneClicked(type: string, dealId: string) {
  return createUserEvent("welcome:tradein_done_click", { type, dealId })
}

export function welcomeFinancingDoneClicked(type: string, dealId: string) {
  return createUserEvent("welcome:financing_done_click", { type, dealId })
}

export function welcomeMoveToChatClicked(dealId: string) {
  return createUserEvent("welcome:move_to_chat_click", { dealId })
}

export function welcomeFinishClicked(dealId: string) {
  return createUserEvent("welcome:finish_click", { dealId })
}

export function dealPageRendered(dealId: string, dealerLocation: string) {
  return createUserEvent("view:deal_page", { dealId, dealerLocation })
}

export function emailUpdated(dealId: string) {
  return createUserEvent("email:update", { dealId })
}

export function emailSaved(dealId: string) {
  return createUserEvent("email:save", { dealId })
}

export function customerNameUpdated(dealId: string) {
  return createUserEvent("customer_name:update", { dealId })
}

export function customerNameSaved(dealId: string) {
  return createUserEvent("customer_name:save", { dealId })
}

export function phoneNumberUpdated(dealId: string) {
  return createUserEvent("phone_number:update", { dealId })
}

export function phoneNumberSaved(dealId: string) {
  return createUserEvent("phone_number:save", { dealId })
}
