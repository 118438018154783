import { createAction } from "@reduxjs/toolkit"
import { isBrowser } from "../../src/utils"
import { startListening } from "../store"

export const recordClientEvent =
  createAction<Record<string, any>>("recordClientEvent")

if (isBrowser) {
  startListening({
    actionCreator: recordClientEvent,
    effect(action, { extra }) {
      const { clientEventRecorder } = extra()
      clientEventRecorder.record(action.payload)
    }
  })
}
