import { isBrowser } from "../../src/utils"
import { startListening } from "../store"
import {
  AUTH_STATUS,
  authenticated,
  selectLocale,
  selectUserId,
  unauthenticated
} from "../slices/userStatus"
import { PushNotifications } from "../../src/push-notifications/PushNotifications"
import { getLongLanguageCode } from "@shared/language/utils"
import type { RegisterPushNotificationsSuccess } from "../../server/controllers/api/notifications/registerNotificationsController"
import { workbox } from "../../service-worker/workbox-window"
import { ApiInstance } from "../../src/api"

if (isBrowser && PushNotifications.isSupported) {
  startListening({
    actionCreator: authenticated,
    async effect(_, { getState, extra }) {
      const { pushNotifications, api } = extra()
      const userId = selectUserId(getState()) as string
      const locale = selectLocale(getState())
      const languageCode = getLongLanguageCode(locale)

      try {
        if (!workbox) {
          return
        }

        /**
         * We can always update the service worker if there's an update available,
         * because we only use it for push notifications for now.
         */
        await workbox.register()
        await workbox.update()
        workbox.messageSkipWaiting()

        const { enabled, token } = await register(api, languageCode)

        // Abort if user is no longer authenticated
        const isAuthenticated =
          getState().userState.authStatus === AUTH_STATUS.AUTHENTICATED
        if (!isAuthenticated) {
          return
        }

        if (enabled) {
          await pushNotifications.start(userId, token)
        } else {
          await pushNotifications.unregisterDevice()
        }
      } catch (err) {
        console.error("failed to start push notifications: ", err)
        await pushNotifications.stop()
      }
    }
  })

  startListening({
    actionCreator: unauthenticated,
    effect: (_, { extra }) => {
      const { pushNotifications } = extra()
      return pushNotifications.unregisterDevice()
    }
  })
}

async function register(api: ApiInstance, languageCode: string) {
  const result = await api.post<
    { language: string },
    RegisterPushNotificationsSuccess
  >("/api/notifications/register", {
    language: languageCode
  })
  if (api.isError(result)) {
    throw new Error(result.error.message)
  }
  return result.data
}
