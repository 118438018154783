import { createAction, createSelector, createSlice } from "@reduxjs/toolkit"
import type { AppState } from "../store"

const SOCKETIO_CONN_STATUS = {
  CONNECTING: "connecting",
  CONNECTED: "connected",
  DISCONNECTED: "disconnected"
} as const

export type SocketioConnStatusType =
  typeof SOCKETIO_CONN_STATUS[keyof typeof SOCKETIO_CONN_STATUS]

interface SocketioSlice {
  connectionStatus: SocketioConnStatusType
}

const initialState: SocketioSlice = {
  connectionStatus: "disconnected"
}

export const connectSocketio = createAction("socketio/connect")

export const disconnectSocketio = createAction("socketio/disconnect")

export const socketioSlice = createSlice({
  name: "socketio",
  initialState,
  reducers: {
    socketioConnecting(state) {
      state.connectionStatus = "connecting"
    },
    socketioConnected(state) {
      state.connectionStatus = "connected"
    },
    socketioDisconnected(state) {
      state.connectionStatus = "disconnected"
    }
  }
})

export const { socketioConnecting, socketioConnected, socketioDisconnected } =
  socketioSlice.actions

const getSocketioSlice = (state: AppState) => state.socketio

export const selectSocketioConnStatus = createSelector(
  getSocketioSlice,
  (socketio) => socketio.connectionStatus
)

export default socketioSlice.reducer
