export function createLoginUrl(
  baseUrl: string,
  locale: string,
  dealId?: string,
  phoneNumber?: string
) {
  const url = new URL(baseUrl + "/api/auth/login")
  url.searchParams.set("locale", locale)
  if (dealId) {
    url.searchParams.set("deal_id", dealId)
  }

  if (phoneNumber) {
    url.searchParams.set("phone", phoneNumber)
  }
  return url.toString()
}
