"use client"
import { getClientInstanceId } from "./getClientInstanceId"
import { isBrowser } from "../utils"

/**
 * Client instance ID is unique id for the current client instance.
 * It has to be unique between same users in the same browser.
 *
 * The ID is persisted to sessionStorage, which means the ID is not lost
 * when the user refreshes the page. But if they open a new client in
 * another tab, that tab will have a different client instance ID.
 */
export const clientInstanceId = isBrowser
  ? getClientInstanceId(window.sessionStorage, "sp-cid")
  : (null as unknown as string)
