import { Modal, Button } from "@mui/material"
import styles from "./ConfirmationDialog.module.css"
import { useDispatch, useSelector } from "../store/store"
import {
  acceptConfirmation,
  declineConfirmation,
  selectConfirmationDialog
} from "../store/slices/confirmationDialog"
import { useTranslation } from "react-i18next"

export const ConfirmationDialog: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const dialog = useSelector(selectConfirmationDialog)

  const handleAccept = () => {
    dispatch(acceptConfirmation(dialog.id as string))
    dialog.handleAccept?.()
  }

  const handleDecline = () => {
    dispatch(declineConfirmation(dialog.id as string))
    dialog.handleDecline?.()
  }

  const isDialogOpen = Boolean(dialog.id)

  if (!isDialogOpen) {
    return null
  }

  return (
    <Modal
      disableScrollLock
      open
      className={styles.modal}
      data-testid="must-login-modal"
      onClose={handleDecline}
    >
      <div className={styles.container}>
        <h3 className={styles.title}>{t(dialog.title)}</h3>
        <div className={styles.description}>{t(dialog.description)}</div>
        <div>
          <Button
            variant="contained"
            color="error"
            data-testid="decline-confirmation"
            className={styles.declineBtn}
            onClick={handleDecline}
          >
            {t("common:reject_btn")}
          </Button>
          <Button
            variant="contained"
            className={styles.acceptBtn}
            data-testid="accept-confirmation"
            onClick={handleAccept}
          >
            {t("common:accept_btn")}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
