import { useCallback } from "react"
import { addGTagEvent } from "../store/actions/addGTagEvent"
import { useDispatch } from "../store/store"

export const useGTag = () => {
  const dispatch = useDispatch()

  const pageview = useCallback(
    (url: string) => {
      dispatch(
        addGTagEvent({
          event: "pageview",
          page_path: url
        })
      )
    },
    [dispatch]
  )

  const addEvent = useCallback(
    (action: string, params?: Record<string, any>) => {
      dispatch(
        addGTagEvent({
          event: action,
          ...params
        })
      )
    },
    [dispatch]
  )

  return { pageview, addEvent } as const
}
