import { Middleware, MiddlewareAPI } from "redux"
import io from "socket.io-client"
import type { DealEventMessageJSON } from "@shared/mongodb-schema/lib/sales-platform/schemas/dealEvent/types"
import { browserConfig } from "../../../src/browserConfig"
import debounce from "lodash.debounce"
import {
  markChatMessagesAsRead,
  markChatMessagesAsReadDebounced,
  markChatMessagesAsReadFailed,
  markChatMessagesAsReadSuccess,
  sendChatMessage,
  sendChatMessageFailed,
  sendChatMessageSuccess,
  subscribeChatMessages,
  unsubscribeChatMessages
} from "../../slices/chat"
import {
  selectDealId,
  subscribeDealUpdates,
  unsubscribeDealUpdates
} from "../../slices/deal"
import {
  connectSocketio,
  disconnectSocketio,
  socketioConnecting,
  socketioConnected,
  socketioDisconnected,
  selectSocketioConnStatus
} from "../../slices/socketio"
import {
  handleSubscribeDealMessages,
  handleUnsubscribeDealMessages
} from "./subscriptions/deal-messages"
import {
  handleSubscribeDealUpdates,
  handleUnsubscribeDealUpdates
} from "./subscriptions/deal-updates"
import { SocketContext, SocketContextConnected } from "./types"

export const createSocketioMiddleware =
  (baseUrl: string, clientInstanceId: string): Middleware =>
  (store) => {
    const context: SocketContext = {
      socket: null,
      store,
      handlers: {}
    }

    return (next) => (action) => {
      next(action)

      switch (true) {
        case connectSocketio.match(action): {
          if (selectSocketioConnStatus(store.getState()) === "disconnected") {
            context.socket = handleConnect(store, baseUrl, clientInstanceId)
          }
          break
        }

        case disconnectSocketio.match(action): {
          context.socket?.disconnect()
          context.socket = null
          context.handlers = {}
          break
        }

        case sendChatMessage.match(action): {
          if (contextHasSocket(context)) {
            handleSendChatMessage(action, context)
          }
          break
        }

        case markChatMessagesAsReadDebounced.match(action): {
          if (contextHasSocket(context)) {
            handleDebounceMarkChatMessagesAsRead(context)
          }
          break
        }

        case markChatMessagesAsRead.match(action): {
          if (contextHasSocket(context)) {
            handleMarkChatMessagesAsRead(action, context)
          }
          break
        }

        case subscribeChatMessages.match(action): {
          if (contextHasSocket(context)) {
            handleSubscribeDealMessages(action, context)
          }
          break
        }

        case unsubscribeChatMessages.match(action): {
          if (contextHasSocket(context)) {
            handleUnsubscribeDealMessages(action, context)
          }
          break
        }

        case subscribeDealUpdates.match(action): {
          if (contextHasSocket(context)) {
            handleSubscribeDealUpdates(action, context)
          }
          break
        }

        case unsubscribeDealUpdates.match(action): {
          if (contextHasSocket(context)) {
            handleUnsubscribeDealUpdates(action, context)
          }
          break
        }

        default:
      }
    }
  }

function contextHasSocket(ctx: SocketContext): ctx is SocketContextConnected {
  return ctx.socket !== null
}

const handleConnect = (
  store: MiddlewareAPI,
  baseUrl: string,
  clientInstanceId: string
) => {
  // TODO: Fix this, not a proper way to get the base URL
  // Just testing to see if this works in production
  const url = new URL(baseUrl)
  const socket = io({
    path:
      url.pathname === "/"
        ? url.pathname.concat("socketio")
        : url.pathname.concat("/socketio"),
    host: url.host,
    // 'beforeunload' event is emitted when user tries to leave the page,
    // it is prevented, and user does not actually leave.
    closeOnBeforeunload: false,
    autoConnect: false,
    forceNew: true,
    timeout: 20000,
    transports: ["websocket"],
    query: {
      clientInstanceId
    }
  })

  socket.on("connect", () => store.dispatch(socketioConnected()))
  socket.on("disconnect", () => store.dispatch(socketioDisconnected()))
  socket.on("reconnect", () => store.dispatch(socketioConnecting()))
  socket.on("reconnected", () => store.dispatch(socketioConnected()))

  store.dispatch(socketioConnecting())
  socket.connect()

  return socket
}

const handleSendChatMessage = (
  action: ReturnType<typeof sendChatMessage>,
  context: SocketContextConnected
) => {
  const dealId = selectDealId(context.store.getState())

  context.socket.emit(
    "deal-messages/send",
    {
      dealId,
      content: action.payload,
      timestamp: new Date().toISOString()
    },
    (error: Error | null, payload: DealEventMessageJSON) => {
      if (error) {
        context.store.dispatch(
          sendChatMessageFailed({ dealId, error: error.message })
        )
        return
      }

      context.store.dispatch(
        sendChatMessageSuccess({ dealId, message: payload })
      )
    }
  )
}

const handleMarkChatMessagesAsRead = (
  action: ReturnType<typeof markChatMessagesAsRead>,
  context: SocketContextConnected
) => {
  const dealId = selectDealId(context.store.getState())

  context.socket.emit(
    "deal-messages/mark-as-read",
    { dealId: action.payload.dealId },
    (error: Error | null) => {
      if (error) {
        context.store.dispatch(
          markChatMessagesAsReadFailed({
            dealId,
            error: error.message
          })
        )
        return
      }

      context.store.dispatch(
        markChatMessagesAsReadSuccess({ dealId: action.payload.dealId })
      )
    }
  )
}

const handleDebounceMarkChatMessagesAsRead = debounce(
  (context: SocketContextConnected) => {
    const dealId = selectDealId(context.store.getState())

    context.store.dispatch(
      markChatMessagesAsRead({
        dealId: dealId
      })
    )
  },
  1000,
  { leading: false, trailing: true }
)
