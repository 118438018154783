function writeToLocalStorage(key, value) {
  try {
    localStorage.setItem(key, value)
    return true
  } catch (err) {
    return false
  }
}

exports.writeToLocalStorage = writeToLocalStorage

function writeToSessionStorage(key, value) {
  try {
    sessionStorage.setItem(key, value)
    return true
  } catch (err) {
    return false
  }
}

exports.writeToSessionStorage = writeToSessionStorage

function readFromLocalStorage(key) {
  try {
    return localStorage.getItem(key)
    // eslint-disable-next-line no-empty
  } catch (err) {}
}

exports.readFromLocalStorage = readFromLocalStorage

function readFromSessionStorage(key) {
  try {
    return sessionStorage.getItem(key)
    // eslint-disable-next-line no-empty
  } catch (err) {}
}

exports.readFromSessionStorage = readFromSessionStorage

function removeFromLocalStorage(key) {
  try {
    return localStorage.removeItem(key)
    // eslint-disable-next-line no-empty
  } catch (err) {}
}

exports.removeFromLocalStorage = removeFromLocalStorage

function removeFromSessionStorage(key) {
  try {
    return sessionStorage.removeItem(key)
    // eslint-disable-next-line no-empty
  } catch (err) {}
}

exports.removeFromSessionStorage = removeFromSessionStorage

function isLocalStorageAccessible() {
  const mod = '_test_local_storage'
  if (!hasLocalStorage()) {
    return false
  }
  try {
    window.localStorage.setItem(mod, mod)
    window.localStorage.removeItem(mod)
    return true
  } catch (e) {
    return false
  }
}

exports.isLocalStorageAccessible = isLocalStorageAccessible

function isSessionStorageAccessible() {
  const mod = '_test_session_storage'
  if (!hasSessionStorage()) {
    return false
  }
  try {
    window.sessionStorage.setItem(mod, mod)
    window.sessionStorage.removeItem(mod)
    return true
  } catch (e) {
    return false
  }
}

exports.isSessionStorageAccessible = isSessionStorageAccessible

function hasLocalStorage() {
  try {
    return !!window.localStorage
  } catch (e) {
    // SecurityError when referencing it means it exists
    return true
  }
}

function hasSessionStorage() {
  try {
    return !!window.sessionStorage
  } catch (e) {
    // SecurityError when referencing it means it exists
    return true
  }
}
