import { useState } from "react"
import { Box, Modal, Typography } from "@mui/material"
import { Button } from "@mui/material-next"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import styles from "../styles/HomePage.module.css"
import { useDispatch, useSelector } from "../store/store"
import { useTranslation } from "react-i18next"
import {
  selectIsConsentOpen,
  selectIsMustLoginOpen,
  acceptTerms,
  declineTerms,
  initializeTerms,
  selectAcceptedTerms
} from "../store/slices/userStatus"
import { useMount } from "../hooks/useMount"
import ConsentInfo from "./consentInfo"
import { recordClientEvent } from "../store/actions/recordClientEvent"
import * as clientEvents from "../src/client-events"
import { useGTag } from "../hooks/useGTag"

function Consent() {
  const { t } = useTranslation()
  const isConsentOpen = useSelector(selectIsConsentOpen)
  const acceptedTerms = useSelector(selectAcceptedTerms)
  const isMustLoginOpen = useSelector(selectIsMustLoginOpen)
  const dispatch = useDispatch()
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false)
  const gtag = useGTag()

  const handleAcceptConsent = () => {
    gtag.addEvent("consent_accepted")
    dispatch(recordClientEvent(clientEvents.consentAccepted()))
    dispatch(acceptTerms())
  }

  const handleRejectConsent = () => {
    gtag.addEvent("consent_declined")
    dispatch(recordClientEvent(clientEvents.consentDeclined()))
    dispatch(declineTerms())
  }

  const handleClickOpenPrivacyPolicyInfo = () => {
    setPrivacyPolicyOpen(true)
  }

  const handleClosePrivacyPolicyInfo = () => {
    setPrivacyPolicyOpen(false)
  }

  useMount(() => {
    dispatch(initializeTerms())
  })

  if (isMustLoginOpen || acceptedTerms) return null
  return (
    <>
      {isConsentOpen && (
        <>
          <Modal disableScrollLock open sx={{ width: "100%" }}>
            <Box className={styles.modal}>
              <AdminPanelSettingsIcon
                color="primary"
                className={styles.modalIcon}
              />
              <Typography variant="h5" gutterBottom>
                {t("common:privacy_setting_title")}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {t("common:privacy_consent_part_1")}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {t("common:privacy_consent_part_2")}
              </Typography>
              <Button variant="text" onClick={handleClickOpenPrivacyPolicyInfo}>
                {t("common:button_read_more_privacy_statement")}
              </Button>
              <Box mt={2}>
                <Button
                  variant="outlined"
                  sx={{ minWidth: 112, marginRight: 1 }}
                  onClick={handleRejectConsent}
                  data-testid="reject-consent"
                >
                  {t("common:reject_btn")}
                </Button>
                <Button
                  variant="filled"
                  sx={{ minWidth: 112 }}
                  onClick={handleAcceptConsent}
                  data-testid="accept-consent"
                >
                  {t("common:accept_btn")}
                </Button>
              </Box>
            </Box>
          </Modal>
          <ConsentInfo
            open={privacyPolicyOpen}
            onClose={handleClosePrivacyPolicyInfo}
          />
        </>
      )}
    </>
  )
}

export default Consent
