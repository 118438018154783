import { createAsyncThunk } from "@reduxjs/toolkit"
import { ThunkConfig } from "../store"
import type { AcceptDealOfferSuccess } from "../../app/api/offer/[id]/accept/route"
import { notifyApiError } from "../slices/notification"

export const acceptDealOffer = createAsyncThunk<
  AcceptDealOfferSuccess["data"],
  string,
  ThunkConfig
>("deal/accept", async (offerId, { dispatch, rejectWithValue, extra }) => {
  const { api } = extra()
  const result = await api.post<undefined, AcceptDealOfferSuccess>(
    `/api/offer/${offerId}/accept`
  )
  if (api.isError(result)) {
    dispatch(notifyApiError(result.error))
    return rejectWithValue(result.error)
  }

  return result.data.data
})
