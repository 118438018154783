import { createAsyncThunk } from "@reduxjs/toolkit"
import type { AppDispatch, AppState, ThunkConfig } from "../store"
import type { OfferViewSuccess } from "../../app/api/offer/[id]/view/route"
import { notifyApiError } from "../slices/notification"
import { selectIsOfferViewed } from "../slices/deal"

export const openDealOffer = (offerId: string) => {
  return (dispatch: AppDispatch, getState: () => AppState) => {
    const isOfferViewed = selectIsOfferViewed(getState(), offerId)
    if (!isOfferViewed) {
      dispatch(markDealOfferViewed(offerId))
    }
  }
}

export const markDealOfferViewed = createAsyncThunk<
  string,
  string,
  ThunkConfig
>(
  "deal/markOfferViewed",
  async (offerId, { dispatch, rejectWithValue, extra }) => {
    const { api } = extra()
    const result = await api.post<undefined, OfferViewSuccess>(
      `/api/offer/${offerId}/view`
    )
    if (api.isError(result)) {
      dispatch(notifyApiError(result.error))
      return rejectWithValue(result.error)
    }

    return result.data.firstViewedAt
  }
)
