const uuid = require('uuid')
/**
 * Formats client events, adds more information and passes the events forward to be saved
 * @param {{ save(...events: Record<string, any>[]): void }} saver
 */
function ClientEventRecorder(saver, opts = {}) {
  validateSaver(saver)
  requireOption(opts, 'client')
  requireOption(opts, 'platform')

  const options = { ...opts }
  if (!options.logger) {
    options.logger = console
  }

  // metadata is meant to contain userId or similar attributes
  let metadata = {}

  return Object.freeze({
    /**
     * @param {...Record<string, any>} events
     */
    record(...events) {
      if (events.length === 0) {
        options.logger.warn(
          'ClientEventRecorder.record was called with zero arguments'
        )
        return
      }
      const extras = options.createExtras ? options.createExtras() : {}
      const enhancedEvents = events.map(event =>
        enhanceEvent(event, options, metadata, extras)
      )
      saver.save(...enhancedEvents)
    },

    updateMetadata(cb) {
      const updated = cb({ ...metadata })
      if (updated) {
        metadata = updated
      } else {
        options.logger.debug(
          'ClientEventRecorder.updateMetadata left metadata falsy. The callback should always return the metadata object.'
        )
      }
    }
  })
}

function enhanceEvent(event, options, metadata, extras) {
  return {
    ...event,
    ...extras,
    ...metadata,
    client: options.client,
    platform: options.platform,
    clientVersion: options.version,
    createdAt: new Date().toISOString(),
    eventId: uuid.v4()
  }
}

function requireOption(options, name) {
  if (!options[name]) {
    throw new Error(`ClientEventRecorder options require property "${name}"`)
  }
}

function validateSaver(saver) {
  if (!saver || !saver.save) {
    throw new Error(
      'ClientEventRecorder requires a valid saver as the first argument'
    )
  }
}

module.exports = ClientEventRecorder
