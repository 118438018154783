import type { DealEventMessageJSON } from "@shared/mongodb-schema/lib/sales-platform/schemas/dealEvent/types"
import {
  addChatMessages,
  addUnreadMessage,
  markChatMessagesAsReadDebounced,
  subscribeChatMessages,
  subscribeChatMessagesFailed,
  subscribeChatMessagesSuccess,
  unsubscribeChatMessages
} from "../../../slices/chat"
import { selectIsChatExpanded } from "../../../slices/deal"
import { selectUserId } from "../../../slices/userStatus"
import { SocketContextConnected } from "../types"

export const handleSubscribeDealMessages = (
  action: ReturnType<typeof subscribeChatMessages>,
  context: SocketContextConnected
) => {
  const dealId = action.payload

  context.socket.emit(
    "deal-messages/subscribe",
    { dealId },
    (error: Error | null) => {
      if (error) {
        context.store.dispatch(
          subscribeChatMessagesFailed({ dealId, error: error.message })
        )
        return
      }

      const eventName = `deal-messages:${dealId}`

      function handler(payload: DealEventMessageJSON) {
        context.store.dispatch(addChatMessages([payload]))

        const chatExpanded = selectIsChatExpanded(context.store.getState())
        const userId = selectUserId(context.store.getState())
        if (
          payload.kind === "message" &&
          userId &&
          payload.senderId !== userId
        ) {
          if (chatExpanded) {
            context.store.dispatch(markChatMessagesAsReadDebounced())
          } else {
            context.store.dispatch(addUnreadMessage(payload._id))
          }
        }
      }

      context.handlers[eventName] = handler

      context.socket.on(eventName, handler)

      context.store.dispatch(subscribeChatMessagesSuccess(dealId))
    }
  )
}

export const handleUnsubscribeDealMessages = (
  action: ReturnType<typeof unsubscribeChatMessages>,
  context: SocketContextConnected
) => {
  const dealId = action.payload
  const eventName = `deal-messages:${dealId}`
  const handler = context.handlers[eventName]

  if (handler) {
    context.socket.off(eventName, handler)
    delete context.handlers[eventName]
  }

  context.socket.emit(`deal-messages/unsubscribe:${dealId}`)
}
