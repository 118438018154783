export const isBrowser = typeof window !== "undefined"

export async function waitUntil(predicate: () => boolean | Promise<boolean>) {
  while (!(await predicate())) {
    await new Promise((resolve) => setTimeout(resolve, 20))
  }
}

const SHORT_ID_CHARS =
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
const SHORT_ID_LENGTH = 12

export function shortId() {
  let id = ""
  for (let i = 0; i < SHORT_ID_LENGTH; i++) {
    id += SHORT_ID_CHARS.charAt(
      Math.floor(Math.random() * SHORT_ID_CHARS.length)
    )
  }
  return id
}
