import { createTheme } from "../src/theme"
import { useAppColors } from "../hooks/useAppColors"
import { useEffect, useMemo } from "react"
import { setCSSVariable } from "../src/setCSSVariable"
import { CssVarsProvider } from "@mui/material-next/styles"
import { roboto } from "../src/fonts"

export const ThemeProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  const colors = useAppColors()

  const theme = useMemo(
    () =>
      createTheme({
        primaryColor: colors.primary,
        secondaryColor: colors.secondary,
        fontFamily: roboto.style.fontFamily
      }),
    [colors.primary, colors.secondary]
  )

  useEffect(() => {
    setCSSVariable(
      "--primary-color",
      theme.colorSchemes.light.palette.primary.main
    )
    setCSSVariable(
      "--primary-text-color",
      theme.colorSchemes.light.palette.primary.contrastText
    )

    setCSSVariable(
      "--secondary-color",

      theme.colorSchemes.light.palette.secondary.main
    )
    setCSSVariable(
      "--secondary-text-color",
      theme.colorSchemes.light.palette.secondary.contrastText
    )
  }, [theme])

  return <CssVarsProvider theme={theme}>{children}</CssVarsProvider>
}
