import { createAsyncThunk } from "@reduxjs/toolkit"
import type { ThunkConfig } from "../store"
import type { RemoveDealSuccess } from "../../app/api/deal/[id]/remove/route"
import { NotificationActions } from "../slices/notification"

export const softDeleteDeal = createAsyncThunk<string, string, ThunkConfig>(
  "deal/softDeleteDeal",
  async (dealId, { rejectWithValue, dispatch, extra }) => {
    const { api } = extra()
    const result = await api.del<undefined, RemoveDealSuccess>(
      `/api/deal/${dealId}/remove`
    )

    if (api.isError(result)) {
      dispatch(
        NotificationActions.addNotification({
          type: "error",
          message: "common:remove_deal_failure"
        })
      )
      return rejectWithValue(result.error)
    }

    dispatch(
      NotificationActions.addNotification({
        type: "success",
        message: "common:remove_deal_success"
      })
    )

    return result.data._id
  }
)
