"use client"
import { I18nextProvider } from "react-i18next"
import { createInstance, type ResourceStore } from "i18next"
import { initTranslations } from "../src/initTranslations"

type Props = {
  locale: string
  resources: ResourceStore["data"]
}

export const TranslationsProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  locale,
  resources
}) => {
  const i18n = createInstance()

  initTranslations(locale, i18n, resources)

  return <I18nextProvider i18n={i18n}>{children as any}</I18nextProvider>
}
