import { createAction, isAnyOf } from "@reduxjs/toolkit"
import { selectDealId, selectFinancing } from "../slices/deal"
import {
  HAS_TRADE_IN_STATUS,
  selectHasTradeInStatus,
  selectTradeinInfo
} from "../slices/tradeinCar"
import {
  acceptTerms,
  declineTerms,
  openConsentModal,
  selectAcceptedTerms,
  selectOnboardingPhoneNumber
} from "../slices/userStatus"
import { startListening } from "../store"
import { notifyApiError } from "../slices/notification"
import type { ApiError } from "../../src/api"
import { saveDealRemote } from "./saveDealRemote"
import {
  acceptConfirmation,
  declineConfirmation,
  showConfirmationDialog
} from "../slices/confirmationDialog"
import { createLoginUrl } from "../../src/createLoginUrl"

export const loginWithDeal = createAction<string>("loginWithDeal")

startListening({
  actionCreator: loginWithDeal,
  async effect(action, { take, dispatch, getState, extra }) {
    const { browserConfig } = extra()
    const confirmationId = "save-deal-login"

    dispatch(
      showConfirmationDialog({
        id: confirmationId,
        title: "common:confirm_login_save_deal_title",
        description: "common:confirm_login_save_deal_description"
      })
    )

    const [confirmSaveDealAction] = await take(
      (action) =>
        isAnyOf(acceptConfirmation, declineConfirmation)(action) &&
        action.payload === confirmationId
    )

    const dealId = selectDealId(getState())

    if (acceptConfirmation.match(confirmSaveDealAction)) {
      const termsAccepted = selectAcceptedTerms(getState())

      if (!termsAccepted) {
        dispatch(openConsentModal())
        await take(
          isAnyOf(
            acceptTerms.fulfilled,
            acceptTerms.rejected,
            declineTerms.fulfilled,
            declineTerms.rejected
          )
        )
      }

      if (!selectAcceptedTerms(getState())) {
        return
      }

      const isTradeInCarSelected =
        selectHasTradeInStatus(getState()) === HAS_TRADE_IN_STATUS.CHECKED
      const financing = selectFinancing(getState())

      const tradeInCar = selectTradeinInfo(getState())

      const saveDealTask = take(
        isAnyOf(saveDealRemote.fulfilled, saveDealRemote.rejected)
      )

      dispatch(
        saveDealRemote({
          dealId,
          tradeInCar: isTradeInCarSelected ? tradeInCar : undefined,
          financing
        })
      )

      const [saveDealAction] = await saveDealTask
      if (saveDealRemote.rejected.match(saveDealAction)) {
        dispatch(
          notifyApiError(
            saveDealAction.payload as ApiError,
            "common:saving_error_notification"
          )
        )
        return
      }
    }

    const phoneNumber = selectOnboardingPhoneNumber(getState())

    window.open(
      createLoginUrl(
        browserConfig.apiBaseUrl,
        action.payload,
        dealId,
        phoneNumber
      ),
      "_self"
    )
  }
})
