import { connectSocketio, disconnectSocketio } from "../store/slices/socketio"
import { useUser } from "../hooks/useUser"
import { useDispatch } from "../store/store"
import { useMount } from "../hooks/useMount"

const SocketioConnection: React.FC = () => {
  const dispatch = useDispatch()

  useMount(() => {
    dispatch(connectSocketio())

    return () => {
      dispatch(disconnectSocketio())
    }
  })

  return null
}

export const SocketioProvider: React.FC = () => {
  const isLoggedIn = Boolean(useUser()?.user)

  return isLoggedIn ? <SocketioConnection /> : null
}
