import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
import { startListening, type ThunkConfig } from "../store"

export const initiateLogout = createAction("initiateLogout")

const submitLogout = createAsyncThunk<void, void, ThunkConfig>(
  "submitLogout",
  (_, { extra }) => {
    const { browserConfig } = extra()
    window.open(`${browserConfig.apiBaseUrl}/api/auth/logout`, "_self")
  }
)

startListening({
  actionCreator: initiateLogout,
  async effect(_, { dispatch, extra }) {
    try {
      const { pushNotifications } = extra()
      await pushNotifications.stop()
    } finally {
      dispatch(submitLogout())
    }
  }
})
