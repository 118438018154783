import { nanoid } from "nanoid"

export function getClientInstanceId(
  storage: Pick<Storage, "getItem" | "setItem">,
  key: string
) {
  if (!storage) {
    throw new Error("getClientInstanceId storage parameter is required")
  }
  if (!key) {
    throw new Error("getClientInstanceId key parameter is required")
  }

  let id = storage.getItem(key)
  if (!id) {
    id = nanoid()
    storage.setItem(key, id)
  }
  return id
}
