"use client"
import {
  ReadonlyURLSearchParams,
  usePathname,
  useSearchParams
} from "next/navigation"
import { useEffect } from "react"

export function useNavigationEvent(callback: (url: string) => void) {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const url = makeUrl(pathname, searchParams)

  useEffect(() => {
    callback(url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])
}

function makeUrl(
  pathname: string | null,
  searchParams: ReadonlyURLSearchParams | null
) {
  return (
    (pathname || "") + (searchParams ? searchParams.toString() : "searchParams")
  )
}
