import { createAsyncThunk } from "@reduxjs/toolkit"
import type { ThunkConfig } from "../store"
import { notifyApiError } from "../slices/notification"
import {
  UpdateDealOnboardingStepParams,
  UpdateDealOnboardingStepSuccess
} from "../../server/controllers/api/deal/updateOnboardingController"
import { selectDealId } from "../slices/deal"
import type { DealOnboardingStatus } from "@shared/mongodb-schema/lib/sales-platform/schemas/deal/types"

export const updateDealOnboarding = createAsyncThunk<
  UpdateDealOnboardingStepSuccess,
  Exclude<DealOnboardingStatus, "NEW">,
  ThunkConfig
>(
  "deal/updateOnboarding",
  async (status, { dispatch, getState, rejectWithValue, extra }) => {
    const { api } = extra()
    const dealId = selectDealId(getState())

    const result = await api.put<
      UpdateDealOnboardingStepParams,
      UpdateDealOnboardingStepSuccess
    >(`/api/deal/${dealId}/onboard`, { status })

    if (api.isError(result)) {
      dispatch(notifyApiError(result.error))
      return rejectWithValue(result.error)
    }

    return result.data
  }
)
