"use client"
import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import Header from "./header"
import Consent from "./consent"
import Cookies from "./cookies"
import Footer from "./footer"
import GTag from "./gtag"
import { Notification } from "./notification"

const Layout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [shouldRender, setShouldRender] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldRender(true)
    }, 1500)
    return () => clearTimeout(timer)
  }, [])

  return (
    <>
      <GTag />
      <Header />
      {shouldRender && <Consent />}
      {shouldRender && <Cookies />}
      <Box
        sx={{
          height: "calc(100vh - 64px)",
          overflow: "hidden",
          marginTop: "64px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexFlow: "column",
            alignItems: "center",
            overflowY: "auto",
            overflowX: "hidden",
            height: "100%"
          }}
          component="main"
        >
          {children}
          <Footer />
        </Box>
        <Notification />
      </Box>
    </>
  )
}

export default Layout
