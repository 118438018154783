import { Snackbar, Alert, Fade, SnackbarCloseReason } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useNotification } from "../hooks/useNotification"
import { selectNotification } from "../store/slices/notification"

export const Notification = (): JSX.Element => {
  const { t } = useTranslation()
  const notification = useSelector(selectNotification)

  const { clearNotification } = useNotification()

  const handleClose = (_: unknown, reason?: SnackbarCloseReason) =>
    reason !== "clickaway" && clearNotification()

  return (
    <Fade in={notification.open}>
      <Snackbar
        open={notification.open}
        autoHideDuration={notification.timeout}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          onClose={handleClose}
          severity={notification.type}
        >
          {t(notification.message)}
        </Alert>
      </Snackbar>
    </Fade>
  )
}
