import { AlertColor } from "@mui/material"
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ApiError } from "../../src/api"
import type { AppDispatch, AppState } from "../store"

export interface NotificationSlice {
  open?: boolean
  type?: AlertColor
  message?: string
  timeout?: number | null
}

const initialState = (): NotificationSlice => ({
  open: false,
  type: "info",
  message: "",
  timeout: 3000
})

export const notifyApiError = (apiError: ApiError, fallback?: string) => {
  return (dispatch: AppDispatch) => {
    /**
     * 1. API translation key
     * 2. fallback translation key in case API error
     *    does not return a translation key
     * 3. ultimate fallback is "An unexpected error occurred"
     */
    const translationKey = apiError?.data?.sId || fallback || "unexpected_error"

    dispatch(
      NotificationActions.addNotification({
        type: "error",
        message: translationKey
      })
    )
  }
}

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (_state, action: PayloadAction<NotificationSlice>) => ({
      ...initialState(),
      ...action.payload,
      open: true
    }),
    clearNotification: (state) => ({ ...state, open: false })
  }
})

const getNotificationSlice = (state: AppState) => state?.notification

export const selectNotification = createSelector(
  getNotificationSlice,
  (notification) => notification
)

export const NotificationActions = notificationSlice.actions

export default notificationSlice.reducer
