import { selectPlatformSettings } from "../store/slices/deal"
import { useSelector } from "../store/store"

export const useAppColors = () => {
  const platform = useSelector(selectPlatformSettings)

  if (platform && platform.primaryColor && platform.secondaryColor) {
    return {
      primary: platform.primaryColor,
      secondary: platform.secondaryColor
    }
  }

  return {
    primary: "#53585f",
    // NOTE: We previously did not use secondary color at all, but now we use it for highlighted actions.
    //       Setting it to same as primary makes colors backwards compatible.
    secondary: "#53585f"
  }
}
