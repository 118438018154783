import { createAsyncThunk } from "@reduxjs/toolkit"
import type { ThunkConfig } from "../store"
import { selectCookieStatus } from "../slices/userStatus"

type GTagEvent = {
  event: string
  [key: string]: any
}

/**
 * Add a Google Tag Manager event only when it is enabled
 */
export const addGTagEvent = createAsyncThunk<void, GTagEvent, ThunkConfig>(
  "addGTagEvent",
  async (event, { getState }) => {
    const cookieStatus = selectCookieStatus(getState())
    const gtagAllowed = cookieStatus === "all"
    if (gtagAllowed && window.dataLayer) {
      window.dataLayer.push(event)
    }
  }
)
