// Calls the function after debounceMs
// or immediately if function calls have been debounced for limitMs
// or immediately if function has been called maxCalls times
function Debouncer({ debounceMs, limitMs, maxCalls, logger = console }) {
  if (!debounceMs)
    throw new Error('[Debouncer]: property debounceMs is required')

  let debounceTimeout = null
  let debounceStartedAt = null
  let callsCount = 0

  return Object.freeze({
    debounce,
    cancel
  })

  function debounce(fn) {
    callsCount += 1

    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
      debounceTimeout = null
    }

    function onTimeout() {
      fn()
      debounceStartedAt = null
      callsCount = 0
    }

    if (maxCalls !== undefined && callsCount >= maxCalls) {
      logger.debug(
        `[Debouncer]: Function calls were debounced ${maxCalls} times. Calling the function.`
      )
      onTimeout()
    } else if (
      limitMs !== undefined &&
      debounceStartedAt &&
      debounceStartedAt.valueOf() < new Date().valueOf() - limitMs
    ) {
      logger.debug(
        `[Debouncer]: Function calls were debounced for ${
          limitMs / 1000
        } seconds. Calling the function.`
      )
      onTimeout()
    } else {
      debounceTimeout = setTimeout(onTimeout, debounceMs)
      if (!debounceStartedAt) {
        debounceStartedAt = new Date()
      }
    }
  }

  function cancel() {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
      debounceTimeout = null
    }
    callsCount = 0
    debounceStartedAt = null
  }
}

module.exports = Debouncer
