"use client"
import React, { useRef } from "react"
import { type AppStore, makeStore } from "./store"
import { Provider } from "react-redux"
import type { BrowserConfig } from "../src/browserConfig"
import "./effects"

type Props = React.PropsWithChildren<{
  browserConfig: BrowserConfig
}>

export const StoreProvider: React.FC<Props> = ({ children, browserConfig }) => {
  const storeRef = useRef<AppStore>()
  if (!storeRef.current) {
    // Create the store instance the first time this renders
    storeRef.current = makeStore(browserConfig)
  }

  return <Provider store={storeRef.current}>{children}</Provider>
}
