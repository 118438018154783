import { createAsyncThunk } from "@reduxjs/toolkit"
import { type ThunkConfig } from "../store"
import type { ApiError } from "../../src/api"

export const sendLoginSms = createAsyncThunk<
  void,
  { onSuccess(): void; onError(error: Error | ApiError): void },
  ThunkConfig
>(
  "sendLoginSms",
  async ({ onSuccess, onError }, { extra, rejectWithValue }) => {
    try {
      const { api } = extra()
      const message =
        "Voit kirjautua takaisin ostoskoriisi osoitteessa https://ostoskori.smilee.io"

      const result = await api.post("/api/send/sms", {
        message
      })

      if (api.isError(result)) {
        onError(result.error)
        return rejectWithValue(result.error)
      } else {
        onSuccess()
      }
    } catch (err) {
      onError(err as Error)
    }
  }
)
