function _createEvent(eventName, initiator, eventDetails, eventVersion) {
  let _event = {
    eventName,
    initiator,
    eventDetails: eventDetails || {}
  }
  if (eventVersion) {
    _event.eventVersion = eventVersion
  }
  return _event
}

exports.createUserEvent = function createUserEvent(
  eventName,
  eventDetails,
  eventVersion
) {
  return _createEvent(eventName, 'user', eventDetails, eventVersion)
}

exports.createAppEvent = function createAppEvent(
  eventName,
  eventDetails,
  eventVersion
) {
  return _createEvent(eventName, 'app', eventDetails, eventVersion)
}

exports.createMockEvent = function createMockEvent(
  eventName = 'mock',
  eventDetails,
  eventVersion
) {
  return _createEvent(eventName, eventDetails, eventVersion)
}
