import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit"
import type { DealJSON } from "@shared/mongodb-schema/lib/sales-platform/schemas/deal/types"
import type { TradeInJSON } from "@shared/mongodb-schema/lib/sales-platform/schemas/tradeIn/types"
import type { SaveTradeInCarParams } from "../../server/controllers/api/tradein/saveTradeInCarController"
import type { AppState, ThunkConfig } from "../store"
import { NotificationActions, notifyApiError } from "./notification"
import type { TradeInCarImageName, TradeInInfo } from "./tradeinCar"
import { softDeleteDeal } from "../actions/softDeleteDeal"
import { SHOPPING_CART_CURRENCY } from "@shared/sales-platform/constants"
import type { ApiInstance } from "../../src/api"
import { initiateDealOnboarding } from "../actions/initiateDealOnboarding"
import { selectDealId } from "./deal"

export const AUTH_STATUS = {
  INITIALIZING: "initializing",
  AUTHENTICATED: "authenticated",
  UNAUTHENTICATED: "unauthenticated"
} as const

export type AuthStatus = typeof AUTH_STATUS[keyof typeof AUTH_STATUS]

interface UserStateSlice {
  acceptedTerms: boolean
  isConsentOpen: boolean
  isMustLoginOpen: boolean
  isForcedLogin: boolean
  isImageUploading: boolean
  cookieStatus: string
  openDeals: { [_id: string]: DealJSON }
  dealOpenOffers: { [dealId: string]: boolean }
  tradeIns: { [_id: string]: TradeInJSON }
  saveTradeInLoading: boolean
  name: string
  userId: string | null
  email: string
  onboardingPhoneNumber: string
  locale: string
  authStatus: AuthStatus
  customerName: string
}

const initialState: UserStateSlice = {
  acceptedTerms: false,
  isConsentOpen: false,
  isMustLoginOpen: false,
  isForcedLogin: false,
  isImageUploading: false,
  saveTradeInLoading: false,
  cookieStatus: "",
  openDeals: {},
  dealOpenOffers: {},
  tradeIns: {},
  name: "",
  userId: null,
  email: "",
  onboardingPhoneNumber: "",
  locale: "fi",
  customerName: "",
  authStatus: AUTH_STATUS.INITIALIZING
}

export const deleteTradeInCar = createAsyncThunk<void, string, ThunkConfig>(
  "userState/deleteTradeInCar",
  async (carId: string, { dispatch, rejectWithValue, extra }) => {
    const { api } = extra()
    const result = await api.del(`/api/tradein/${carId}`)

    if (api.isError(result)) {
      dispatch(notifyApiError(result.error))
      return rejectWithValue(result.error)
    }
  }
)

export const uploadUserTradeInCarImage = createAsyncThunk<
  string,
  // NOTE: imageName and carId are used when updating state
  {
    blob: Blob
    carId: string
    imageName: TradeInCarImageName
    onSuccess(url: string): void
  },
  ThunkConfig
>(
  "userState/uploadImage",
  async ({ blob, onSuccess }, { dispatch, rejectWithValue, extra }) => {
    const { api } = extra()
    const formData = new FormData()
    formData.append("image", blob)

    const result = await api.postForm<{ url: string }>(
      "/api/upload/image",
      formData
    )

    if (api.isError(result)) {
      dispatch(notifyApiError(result.error))
      return rejectWithValue(result.error)
    }

    onSuccess(result.data.url)

    return result.data.url
  }
)

export const saveTradeInCar = createAsyncThunk<
  TradeInJSON,
  TradeInInfo,
  ThunkConfig
>(
  "userState/saveTradeInCar",
  async (tradeInCar, { dispatch, rejectWithValue, extra }) => {
    const { api } = extra()
    const params = makeSaveTradeInCarParams(tradeInCar)
    const result = await api.put<SaveTradeInCarParams, TradeInJSON>(
      `/api/tradein/${tradeInCar._id}`,
      params
    )

    if (api.isError(result)) {
      return rejectWithValue(result.error)
    }

    dispatch(
      NotificationActions.addNotification({
        type: "success",
        message: "common:save_tradein_success"
      })
    )

    return result.data
  }
)

function makeSaveTradeInCarParams(
  tradeInCar: TradeInInfo
): SaveTradeInCarParams {
  return {
    licensePlate: tradeInCar.licensePlate,
    additionalInfo: tradeInCar.additionalInfo,
    imgAdditional1: tradeInCar.imgAdditional1,
    imgAdditional2: tradeInCar.imgAdditional2,
    imgBack: tradeInCar.imgBack,
    imgFront: tradeInCar.imgFront,
    imgLeft: tradeInCar.imgLeft,
    imgRight: tradeInCar.imgRight,
    imgServiceBook: tradeInCar.imgServiceBook,
    imgSummerTires: tradeInCar.imgSummerTires,
    imgWinterTires: tradeInCar.imgWinterTires,
    kilometers: tradeInCar.kilometers,
    loanAmount: tradeInCar.loanAmount,
    loanType: tradeInCar.loanType,
    summerTires: tradeInCar.summerTires,
    windshieldCondition: tradeInCar.windshieldCondition,
    winterTires: tradeInCar.winterTires
  }
}

export const saveUserLanguage = createAsyncThunk<void, string, ThunkConfig>(
  "userState/saveUserLanguage",
  async (locale, { extra }) => {
    const { api } = extra()
    await api.put(`/api/user/language/${locale}`)
  }
)

export const initializeTerms = createAsyncThunk<boolean, void, ThunkConfig>(
  "userState/initializeTerms",
  async (_, { extra, rejectWithValue }) => {
    const { api } = extra()
    const result = await api.get<{ accepted: boolean }>(`/api/user/terms`)
    if (api.isError(result)) {
      return rejectWithValue(new Error(result.error.message))
    }
    return result.data.accepted
  }
)

export const saveTerms = async (api: ApiInstance, accepted: boolean) => {
  await api.put(`/api/user/terms`, { accepted })
}

export const acceptTerms = createAsyncThunk<void, void, ThunkConfig>(
  "userState/acceptTerms",
  async (_, { extra }) => {
    const { api } = extra()
    await saveTerms(api, true)
  }
)

export const declineTerms = createAsyncThunk<void, void, ThunkConfig>(
  "userState/declineTerms",
  async (_, { extra }) => {
    const { api } = extra()
    await saveTerms(api, false)
  }
)

export const saveEmail = createAsyncThunk<
  void,
  { email: string; onSuccess?: () => void },
  ThunkConfig
>(
  "userState/saveEmail",
  async (
    { email, onSuccess },
    { getState, dispatch, extra, rejectWithValue }
  ) => {
    const { api } = extra()
    const userId = selectUserId(getState())
    const dealId = selectDealId(getState())

    const path = userId ? "/api/user/email" : `/api/user/deal/${dealId}/email`
    const result = await api.put(path, { email })

    if (api.isError(result)) {
      dispatch(
        NotificationActions.addNotification({
          type: "error",
          message: "common:email_update_failure"
        })
      )
      return rejectWithValue(result.error)
    } else {
      dispatch(
        NotificationActions.addNotification({
          type: "success",
          message: "common:email_update_success"
        })
      )
      if (onSuccess) onSuccess()
    }
  }
)

export const savePhoneNumber = createAsyncThunk<
  void,
  { phoneNumber: string; onSuccess?: () => void },
  ThunkConfig
>(
  "userState/savePhoneNumber",
  async (
    { phoneNumber, onSuccess },
    { getState, dispatch, extra, rejectWithValue }
  ) => {
    const { api } = extra()
    const userId = selectUserId(getState())
    const dealId = selectDealId(getState())

    const path = userId ? "/api/user/phone" : `/api/user/deal/${dealId}/phone`
    const result = await api.put(path, { phoneNumber })

    if (api.isError(result)) {
      dispatch(
        NotificationActions.addNotification({
          type: "error",
          message: "common:phone_update_failure"
        })
      )
      return rejectWithValue(result.error)
    } else {
      dispatch(
        NotificationActions.addNotification({
          type: "success",
          message: "common:phone_update_success"
        })
      )
      if (onSuccess) onSuccess()
    }
  }
)

export const saveCustomerName = createAsyncThunk<
  void,
  { customerName: string; onSuccess?: () => void },
  ThunkConfig
>(
  "userState/saveCustomerName",
  async (
    { customerName, onSuccess },
    { getState, dispatch, extra, rejectWithValue }
  ) => {
    const { api } = extra()
    const userId = selectUserId(getState())
    const dealId = selectDealId(getState())

    const path = userId
      ? "/api/user/customername"
      : `/api/user/deal/${dealId}/customername`
    const result = await api.put(path, {
      customerName
    })

    if (api.isError(result)) {
      dispatch(
        NotificationActions.addNotification({
          type: "error",
          message: "common:customer_name_update_failure"
        })
      )
      return rejectWithValue(result.error)
    } else {
      dispatch(
        NotificationActions.addNotification({
          type: "success",
          message: "common:customer_name_update_success"
        })
      )
      if (onSuccess) onSuccess()
    }
  }
)

export const userStateSlice = createSlice({
  name: "userState",
  initialState,
  reducers: {
    openConsentModal(state) {
      state.isConsentOpen = true
    },
    openLoginModal(state) {
      state.isMustLoginOpen = true
    },
    acceptLogin(state) {
      state.isMustLoginOpen = false
    },
    declineLogin(state) {
      state.isMustLoginOpen = false
    },
    setIsForcedLogin(state, action: PayloadAction<boolean>) {
      state.isForcedLogin = action.payload
    },
    setOpenDeals(state, action: PayloadAction<DealJSON[]>) {
      state.openDeals = {}
      for (const deal of action.payload) {
        state.openDeals[deal._id] = deal
      }
    },
    setTradeIns(state, action: PayloadAction<TradeInJSON[]>) {
      state.tradeIns = {}
      for (const tradeIn of action.payload) {
        state.tradeIns[tradeIn._id] = tradeIn
      }
    },
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload
    },
    setUserId(state, action: PayloadAction<string | null>) {
      state.userId = action.payload
    },
    setCookieStatus(state, action: PayloadAction<string>) {
      state.cookieStatus = action.payload
    },
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload
    },
    setCustomerName(state, action: PayloadAction<string>) {
      state.customerName = action.payload
    },
    setOnboardingPhoneNumber(state, action: PayloadAction<string>) {
      state.onboardingPhoneNumber = action.payload
    },
    setDealOpenOffers(
      state,
      action: PayloadAction<{ [dealId: string]: boolean }>
    ) {
      state.dealOpenOffers = action.payload
    },
    authenticated(state) {
      state.authStatus = AUTH_STATUS.AUTHENTICATED
    },
    unauthenticated(state) {
      state.authStatus = AUTH_STATUS.UNAUTHENTICATED
    },
    setLocale(state, action: PayloadAction<string>) {
      state.locale = action.payload
    }
  },
  extraReducers(builder) {
    builder.addCase(initiateDealOnboarding.fulfilled, (state, action) => {
      state.email = action.meta.arg.email
      state.customerName = action.meta.arg.customerName
      state.onboardingPhoneNumber = action.meta.arg.phoneNumber
    })

    builder.addCase(acceptTerms.fulfilled, (state) => {
      state.acceptedTerms = true
      state.isConsentOpen = false
    })

    builder.addCase(declineTerms.fulfilled, (state) => {
      state.acceptedTerms = false
      state.isConsentOpen = false
    })

    builder.addCase(initializeTerms.fulfilled, (state, action) => {
      state.acceptedTerms = action.payload === true
      state.isConsentOpen = action.payload !== true
    })

    builder.addCase(deleteTradeInCar.fulfilled, (state, action) => {
      const carId = action.meta.arg
      if (carId in state.tradeIns) {
        delete state.tradeIns[carId]
      }
    })

    builder.addCase(saveTradeInCar.fulfilled, (state, action) => {
      if (action.payload._id in state.tradeIns) {
        state.tradeIns[action.payload._id] = action.payload
      }
    })

    builder.addCase(uploadUserTradeInCarImage.pending, (state) => {
      state.isImageUploading = true
    })

    builder.addCase(uploadUserTradeInCarImage.rejected, (state) => {
      state.isImageUploading = false
    })

    builder.addCase(uploadUserTradeInCarImage.fulfilled, (state) => {
      state.isImageUploading = false
    })

    builder.addCase(saveEmail.fulfilled, (state, action) => {
      state.email = action.meta.arg.email
    })

    builder.addCase(saveCustomerName.fulfilled, (state, action) => {
      state.customerName = action.meta.arg.customerName
    })

    builder.addCase(savePhoneNumber.fulfilled, (state, action) => {
      state.onboardingPhoneNumber = action.meta.arg.phoneNumber
    })

    builder.addCase(softDeleteDeal.fulfilled, (state, action) => {
      const dealId = action.payload
      delete state.openDeals[dealId]
      delete state.dealOpenOffers[dealId]
    })
  }
})

export const {
  openConsentModal,
  openLoginModal,
  acceptLogin,
  declineLogin,
  setIsForcedLogin,
  setOpenDeals,
  setTradeIns,
  setName,
  setUserId,
  setCookieStatus,
  setDealOpenOffers,
  setEmail,
  setCustomerName,
  setOnboardingPhoneNumber,
  setLocale,
  authenticated,
  unauthenticated
} = userStateSlice.actions

const getUserStateSlice = (state: AppState) => state.userState

export const selectAcceptedTerms = createSelector(
  getUserStateSlice,
  (userState) => userState.acceptedTerms
)

export const selectIsConsentOpen = createSelector(
  getUserStateSlice,
  (userState) => userState.isConsentOpen
)

export const selectIsMustLoginOpen = createSelector(
  getUserStateSlice,
  (userState) => userState.isMustLoginOpen
)

export const selectIsForcedLogin = createSelector(
  getUserStateSlice,
  (userState) => userState.isForcedLogin
)

const getOpenDeals = createSelector(
  getUserStateSlice,
  (userState) => userState.openDeals
)

export const selectOpenDeals = createSelector(getOpenDeals, (openDeals) =>
  Object.values(openDeals)
)

const getTradeIns = createSelector(
  getUserStateSlice,
  (userState) => userState.tradeIns
)

export const selectTradeIns = createSelector(getTradeIns, (tradeIns) =>
  Object.values(tradeIns)
)

export const selectName = createSelector(
  getUserStateSlice,
  (userState) => userState.name
)

export const selectEmail = createSelector(
  getUserStateSlice,
  (userState) => userState.email
)

export const selectCustomerName = createSelector(
  getUserStateSlice,
  (userState) => userState.customerName
)

export const selectOnboardingPhoneNumber = createSelector(
  getUserStateSlice,
  (userState) => userState.onboardingPhoneNumber
)

export const selectUserId = createSelector(
  getUserStateSlice,
  (userState) => userState.userId
)

export const selectLocale = createSelector(
  getUserStateSlice,
  (userState) => userState.locale
)

export const selectCookieStatus = createSelector(
  getUserStateSlice,
  (userState) => userState.cookieStatus
)

export const getHasOpenOffer = createSelector(
  getUserStateSlice,
  (_: unknown, dealId: string) => dealId,
  (userState, dealId) => userState.dealOpenOffers[dealId] === true
)

export const selectTradeInCurrencyType = createSelector(
  getOpenDeals,
  getTradeIns,
  (_: unknown, tradeInId: string) => tradeInId,
  (_: unknown, _tradeInId: string, dealId?: string) => dealId,
  (openDeals, tradeIns, tradeInId, dealId) => {
    const tradeInCurrencyType = tradeIns[tradeInId]?.currencyType
    const dealCurrencyType = openDeals[dealId as string]?.currencyType
    return tradeInCurrencyType || dealCurrencyType || SHOPPING_CART_CURRENCY.EUR
  }
)

export default userStateSlice.reducer
