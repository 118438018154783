import {
  defaultTheme,
  extendTheme,
  MD3NeutralTones,
  MD3Tones
} from "@mui/material-next/styles"
import {
  argbFromHex,
  themeFromSourceColor,
  hexFromArgb
} from "@material/material-color-utilities"
import merge from "lodash/merge"

type ThemeParams = {
  primaryColor: string
  secondaryColor: string
  fontFamily: string
}

const steps = [
  0, 10, 17, 20, 22, 30, 40, 50, 60, 70, 80, 90, 92, 95, 96, 99, 100
]

// Create a theme instance.
export const createTheme = (params: ThemeParams) => {
  const primaryPalette: MD3Tones = {
    0: "",
    10: "",
    20: "",
    30: "",
    40: "",
    50: "",
    60: "",
    70: "",
    80: "",
    90: "",
    95: "",
    99: "",
    100: ""
  }
  const secondaryPalette: MD3Tones = {
    0: "",
    10: "",
    20: "",
    30: "",
    40: "",
    50: "",
    60: "",
    70: "",
    80: "",
    90: "",
    95: "",
    99: "",
    100: ""
  }
  const tertiaryPalette: MD3Tones = {
    0: "",
    10: "",
    20: "",
    30: "",
    40: "",
    50: "",
    60: "",
    70: "",
    80: "",
    90: "",
    95: "",
    99: "",
    100: ""
  }
  const neutralPalette: MD3NeutralTones = {
    0: "",
    10: "",
    17: "",
    20: "",
    22: "",
    30: "",
    40: "",
    50: "",
    60: "",
    70: "",
    80: "",
    90: "",
    92: "",
    95: "",
    96: "",
    99: "",
    100: ""
  }
  const neutralVariantPalette: MD3NeutralTones = {
    0: "",
    10: "",
    17: "",
    20: "",
    22: "",
    30: "",
    40: "",
    50: "",
    60: "",
    70: "",
    80: "",
    90: "",
    92: "",
    95: "",
    96: "",
    99: "",
    100: ""
  }

  const theme = themeFromSourceColor(argbFromHex(params.primaryColor))

  for (const [key, palette] of Object.entries(theme.palettes)) {
    const paletteKey = key.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()

    for (const tone of steps) {
      switch (paletteKey) {
        case "primary":
          if (!(tone in primaryPalette)) break
          primaryPalette[tone as keyof MD3Tones] = hexFromArgb(
            palette.tone(tone)
          )
          break
        case "secondary":
          if (!(tone in secondaryPalette)) break
          secondaryPalette[tone as keyof MD3Tones] = hexFromArgb(
            palette.tone(tone)
          )
          break
        case "tertiary":
          if (!(tone in tertiaryPalette)) break
          tertiaryPalette[tone as keyof MD3Tones] = hexFromArgb(
            palette.tone(tone)
          )
          break
        case "neutral":
          if (!(tone in neutralPalette)) break
          neutralPalette[tone as keyof MD3Tones] = hexFromArgb(
            palette.tone(tone)
          )
          break
        case "neutral-variant":
          if (!(tone in neutralVariantPalette)) break
          neutralVariantPalette[tone as keyof MD3Tones] = hexFromArgb(
            palette.tone(tone)
          )
          break
        default:
          break
      }
    }
  }

  const fontTypescale = {
    large: {
      family: params.fontFamily
    },
    medium: {
      family: params.fontFamily
    },
    small: {
      family: params.fontFamily
    }
  }

  return extendTheme({
    components: {
      MuiCard: {
        styleOverrides: {
          root: {
            background: "#fafafa"
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            background: "#fff"
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            background: "#fff"
          }
        }
      }
    },
    typography: {
      fontFamily: params.fontFamily
    },
    colorSchemes: {
      light: {
        palette: {
          primary: {
            main: params.primaryColor
          },
          secondary: {
            main: params.secondaryColor
          },
          success: { main: "#00294D" },
          error: {
            main: "#DE2362"
          }
        }
      },
      dark: {
        palette: {
          primary: {
            main: params.primaryColor
          },
          secondary: {
            main: params.secondaryColor
          },
          success: { main: "#00294D" },
          error: {
            main: "#DE2362"
          }
        }
      }
    },
    ref: {
      typeface: {
        brand: params.fontFamily,
        plain: params.fontFamily
      },
      palette: {
        primary: primaryPalette,
        secondary: secondaryPalette,
        tertiary: tertiaryPalette,
        neutral: neutralPalette,
        neutralVariant: neutralVariantPalette
      }
    },
    sys: {
      // keep default sizes etc. and only override the font family
      typescale: merge({}, defaultTheme.sys.typescale, {
        body: fontTypescale,
        display: fontTypescale,
        headline: fontTypescale,
        label: fontTypescale
      })
    }
  })
}
