const DEAL_EVENTS_JOB_QUEUES = {
  READ_STATUS_UPDATES: 'deal-events-read-status-updates'
}

const SHOPPING_CART_CURRENCY = {
  /** @type {'EUR'} */
  EUR: 'EUR',
  /** @type {'SEK'} */
  SEK: 'SEK'
}

const SHOPPING_CART_CURRENCY_SYMBOLS = {
  /** @type {'€'} */
  EUR: '€',
  /** @type {'kr'} */
  SEK: 'kr'
}

module.exports = {
  DEAL_EVENTS_JOB_QUEUES,
  SHOPPING_CART_CURRENCY,
  SHOPPING_CART_CURRENCY_SYMBOLS
}
