import ms from "ms"
import type { Client as PusherBeamsClient } from "@pusher/push-notifications-web"

type CreateClient = (
  serviceWorkerRegistration: ServiceWorkerRegistration
) => PusherBeamsClient

export class PushNotifications {
  createClient: CreateClient
  client: PusherBeamsClient | null

  /**
   * TODO: consider creating client instance directly in
   *       the class when ESM modules are usable in tests.
   *
   *       It would enable automatic mocking of Pusher client
   *       methods which would probably be better than having a manually
   *       created mock client which isn't linked to the real client.
   */
  constructor({ createClient }: { createClient: CreateClient }) {
    this.createClient = createClient
    this.client = null

    this.getPermissions = this.getPermissions.bind(this)
    this.subscribeTopics = this.subscribeTopics.bind(this)
    this.start = this.start.bind(this)
    this.stop = this.stop.bind(this)
    this.unregisterDevice = this.unregisterDevice.bind(this)
  }

  /**
   * Is Pusher client supported
   *
   * https://github.com/pusher/push-notifications-web/blob/c68dbd8754baf1e50b298d6705057f83734bd10d/src/push-notifications.js#L42-L64
   */
  static get isSupported() {
    return (
      typeof window !== "undefined" &&
      "Notification" in window &&
      "serviceWorker" in navigator &&
      "PushManager" in window &&
      "indexedDB" in window &&
      window.isSecureContext
    )
  }

  get isSupported() {
    return PushNotifications.isSupported
  }

  /**
   * Is the client set up and has permissions to show notifications on the device?
   *
   * https://pusher.com/docs/beams/reference/web/#getregistrationstate
   */
  async getPermissions() {
    if (!this.client) {
      return false
    }

    const registrationState = await this.client.getRegistrationState()
    return registrationState === "PERMISSION_GRANTED_REGISTERED_WITH_BEAMS"
  }

  async subscribeTopics(topics: string[]) {
    await this.client!.setDeviceInterests(topics)
  }

  async start(userId: string, token: string) {
    if (!userId) {
      throw new Error("userId is required")
    }
    if (!token) {
      throw new Error("token is required")
    }

    const swRegistration = await this.getServiceWorkerRegistration()

    swRegistration.active!.postMessage({ type: "START_PUSH_NOTIFICATIONS" })

    this.client = this.createClient(swRegistration)
    await this.client.start()
    await this._updateUser(userId, token)
  }

  async stop() {
    if (this.client) {
      const swRegistration = await this.getServiceWorkerRegistration()
      swRegistration.active!.postMessage({
        type: "STOP_PUSH_NOTIFICATIONS"
      })
      await this.client.stop()
      this.client = null
    }
  }

  /**
   * Device needs to be unregistered from Pusher Beams registry by first
   * starting the client to set it up, then stopping it which clears its data.
   */
  async unregisterDevice() {
    const swRegistration = await this.getServiceWorkerRegistration()

    swRegistration.active!.postMessage({ type: "STOP_PUSH_NOTIFICATIONS" })
    if (!this.client) {
      this.client = this.createClient(swRegistration)
      await this.client.start()
    }
    await this.client.stop()
    this.client = null
  }

  /**
   * Assign the device to the current user if it wasn't already
   */
  async _updateUser(userId: string, token: string) {
    if (!userId) {
      throw new Error("userId is required")
    }
    if (!token) {
      throw new Error("token is required")
    }

    const prevUserId = await this.client!.getUserId()
    if (prevUserId !== userId) {
      await this.client!.clearAllState()
      await this.client!.setUserId(userId, {
        fetchToken: () => Promise.resolve({ token })
      })
    }
  }

  async getServiceWorkerRegistration() {
    return getActiveServiceWorkerRegistration(GET_SW_TIMEOUT_MS)
  }
}

export const GET_SW_TIMEOUT_MS = ms("10s")

/**
 * Get active service worker registration when it's ready or time out
 */
function getActiveServiceWorkerRegistration(
  timeoutMs: number = 10 * 1000
): Promise<ServiceWorkerRegistration> {
  return new Promise((resolve, reject) => {
    let timedOut = false

    const timeoutId = setTimeout(() => {
      timedOut = true
      reject(new Error("Timed out"))
    }, timeoutMs)

    navigator.serviceWorker.ready.then((swRegistration) => {
      if (!timedOut) {
        clearTimeout(timeoutId)
        resolve(swRegistration)
      }
    })
  })
}
