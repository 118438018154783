"use client"
import Script from "next/script"
import { useDispatch, useSelector } from "../store/store"
import { selectCookieStatus, setCookieStatus } from "../store/slices/userStatus"
import { useMount } from "../hooks/useMount"
import useStorage from "../hooks/useStorage"
import { useNavigationEvent } from "../hooks/useNavigationEvent"
import { useGTag } from "../hooks/useGTag"

function GTag() {
  const dispatch = useDispatch()
  const { getItem } = useStorage()

  useMount(() => {
    const cookieStatus = getItem("cookies-allowed", "local")
    if (cookieStatus) {
      dispatch(setCookieStatus(cookieStatus))
    }
  })

  const cookieStatus = useSelector(selectCookieStatus)
  const gtag = useGTag()

  const gtagAllowed = cookieStatus === "all"

  useNavigationEvent((url) => {
    if (gtagAllowed) {
      gtag.pageview(url)
    }
  })

  if (!gtagAllowed) return null

  return (
    <>
      <Script
        id="gtag"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NHSP94S');`
        }}
      ></Script>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NHSP94S"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        }}
      ></noscript>
    </>
  )
}

export default GTag
