import MenuIcon from "@mui/icons-material/Menu"
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded"
import Image from "next/image"
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Modal,
  Typography,
  ButtonGroup
} from "@mui/material"
import styles from "../styles/HomePage.module.css"
import { useUser } from "../hooks/useUser"
import { usePathname, useRouter } from "next/navigation"
import { useTranslation } from "react-i18next"
import { useCurrentLocale } from "next-i18n-router/client"
import React, { useState } from "react"
import { useNotification } from "../hooks/useNotification"
import { useGTag } from "../hooks/useGTag"
import { useDispatch, useStore } from "../store/store"
import { loginWithDeal } from "../store/actions/loginWithDeal"
import { recordClientEvent } from "../store/actions/recordClientEvent"
import * as clientEvents from "../src/client-events"
import { HeaderLogo } from "./HeaderLogo"
import { createLoginUrl } from "../src/createLoginUrl"
import { saveUserLanguage } from "../store/slices/userStatus"
import { initiateLogout } from "../store/actions/initiateLogout"
import i18nConfig from "../i18n.config"
import { sendLoginSms } from "../store/actions/sendLoginSms"

function Header() {
  const isLoggedIn = Boolean(useUser()?.user)
  const { t } = useTranslation()
  const router = useRouter()
  const activeLocale = useCurrentLocale(i18nConfig)
  const pathname = usePathname()!
  const { displayNotification } = useNotification()
  const dispatch = useDispatch()
  const gtag = useGTag()
  const store = useStore()

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setMenuAnchorEl(null)
  }

  const [sendSmsState, setSmsState] = useState({ open: false, loading: false })
  const handleSendOpen = () => {
    setMenuAnchorEl(null)
    setSmsState({ open: true, loading: false })
  }
  const handleSendClose = () => setSmsState({ open: false, loading: false })

  const [localeOpen, setLocaleOpen] = useState(false)
  const handleLocalOpen = () => {
    setMenuAnchorEl(null)
    setLocaleOpen(true)
  }

  const handleLocaleClose = () => setLocaleOpen(false)

  const handleUserClick = () => {
    router.push("/user")
  }

  const handleSendSms = async () => {
    dispatch(recordClientEvent(clientEvents.sendSmsLinkClicked()))

    setSmsState((state) => ({ ...state, loading: true }))

    dispatch(
      sendLoginSms({
        onSuccess() {
          handleSendClose()
          displayNotification({
            type: "success",
            message: "common:sms_sent_notification"
          })
          gtag.addEvent("login_sms_sent", {
            location: "header"
          })
        },
        onError() {
          handleSendClose()
          displayNotification({
            type: "error",
            message: "common:sms_sent_error"
          })
        }
      })
    )
  }

  const handleLoginClick = () => {
    gtag.addEvent("login", {
      location: "header"
    })
    dispatch(recordClientEvent(clientEvents.loginClicked("header")))

    if (pathname.startsWith("/cars")) {
      dispatch(loginWithDeal(activeLocale as string))
    } else {
      window.open(
        createLoginUrl(
          store.extra().browserConfig.apiBaseUrl,
          activeLocale as string
        ),
        "_self"
      )
    }
  }

  const handleLogoutClick = () => {
    gtag.addEvent("logout", {
      location: "header"
    })
    dispatch(recordClientEvent(clientEvents.logoutClicked()))
    dispatch(initiateLogout())
  }

  function handleChangeLanguage(newLocale: string) {
    if (activeLocale === newLocale) {
      return
    }

    if (activeLocale === i18nConfig.defaultLocale) {
      router.push("/" + newLocale + pathname)
    } else {
      router.push(
        pathname === `/${activeLocale}`
          ? "/"
          : pathname.replace(`/${activeLocale}`, "")
      )
    }

    router.refresh()

    handleLocaleClose()

    if (isLoggedIn && newLocale !== activeLocale) {
      dispatch(saveUserLanguage(newLocale))
    }
  }

  return (
    <AppBar
      position="fixed"
      component="nav"
      sx={{ paddingRight: "0 !important" }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={handleClick}
          data-testid="header-menu-button"
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <HeaderLogo />
        </Box>
        {isLoggedIn ? (
          <IconButton
            onClick={handleUserClick}
            color="inherit"
            data-testid="user-button"
          >
            <AccountCircleRoundedIcon />
          </IconButton>
        ) : (
          <Button
            sx={{ maxWidth: "64px" }}
            color="inherit"
            data-testid="header-login-button"
            onClick={handleLoginClick}
          >
            {t("common:login_btn")}
          </Button>
        )}
      </Toolbar>
      <Menu
        disableScrollLock
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
      >
        <MenuItem onClick={handleLocalOpen}>
          {t("common:switch_locale")}
        </MenuItem>
        <MenuItem onClick={handleSendOpen} disabled={!isLoggedIn}>
          {t("common:send_link_to")}
        </MenuItem>
        <MenuItem
          data-testid="logout-menu-button"
          disabled={!isLoggedIn}
          onClick={handleLogoutClick}
        >
          {t("common:logout")}
        </MenuItem>
      </Menu>
      <Modal
        disableScrollLock
        open={localeOpen}
        onClose={handleLocaleClose}
        aria-labelledby="switch-locale"
        aria-describedby="a modal where you can set the locale of the page"
      >
        <Box className={styles.modal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("common:choose_locale_title")}
          </Typography>

          <ButtonGroup
            variant="outlined"
            aria-label="language selection"
            orientation="vertical"
            size="large"
            sx={{ maxWidth: "150px", margin: "16px auto" }}
          >
            <Button
              variant={activeLocale === "fi" ? "contained" : "outlined"}
              sx={{ display: "flex", justifyContent: "space-evenly" }}
              onClick={() => handleChangeLanguage("fi")}
              startIcon={
                <Image
                  src="https://cdn.smilee.io/flags/fi.svg"
                  width={20}
                  height={20}
                  alt="Finnish flag"
                />
              }
            >
              Suomi
            </Button>

            <Button
              variant={activeLocale === "sv" ? "contained" : "outlined"}
              sx={{ display: "flex", justifyContent: "space-evenly" }}
              onClick={() => handleChangeLanguage("sv")}
              startIcon={
                <Image
                  src="https://cdn.smilee.io/flags/se.svg"
                  width={20}
                  height={20}
                  alt="Swedish flag"
                />
              }
            >
              Svenska
            </Button>

            <Button
              variant={activeLocale === "en" ? "contained" : "outlined"}
              onClick={() => handleChangeLanguage("en")}
              sx={{ display: "flex", justifyContent: "space-evenly" }}
              startIcon={
                <Image
                  src="https://cdn.smilee.io/flags/gb.svg"
                  width={20}
                  height={20}
                  alt="English flag"
                />
              }
            >
              English
            </Button>
          </ButtonGroup>
          <Button
            onClick={handleLocaleClose}
            sx={{ maxWidth: "80px", marginLeft: "auto" }}
          >
            {t("common:button_close")}
          </Button>
        </Box>
      </Modal>
      <Modal
        disableScrollLock
        open={sendSmsState.open}
        onClose={handleSendClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={styles.modal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("common:send_link_to")}
          </Typography>
          <Typography
            id="modal-sms-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            {t("common:as_sms")}
          </Typography>
          <Button
            sx={{ margin: "auto", mb: 1 }}
            variant="contained"
            disabled={!isLoggedIn || sendSmsState.loading}
            onClick={handleSendSms}
          >
            {t("common:send_sms")}
          </Button>
          {/* <Divider sx={{ margin: 2 }}>{t("common:or")}</Divider>
          <Typography
            id="modal-sms-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            {t("common:as_email")}
          </Typography>

          <TextField
            id="outlined-basic"
            label="Sähköposti"
            variant="outlined"
          />
          <Button sx={{ m: "auto", mb: 1, mt: 1 }} variant="contained">
            {t("common:send_email")}
          </Button> */}
        </Box>
      </Modal>
    </AppBar>
  )
}

export default Header
