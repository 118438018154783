import { i18n, createInstance, type ResourceStore } from "i18next"
import i18nConfig from "../i18n.config"
import { initReactI18next } from "react-i18next/initReactI18next"
import resourcesToBackend from "i18next-resources-to-backend"

export async function initTranslations(
  locale: string,
  i18nInstance?: i18n,
  resources?: ResourceStore["data"]
) {
  const instance = i18nInstance || createInstance()

  instance.use(initReactI18next)

  if (!resources) {
    instance.use(
      resourcesToBackend(
        (language: string, namespace: string) =>
          import(`../public/locales/${language}/${namespace}.json`)
      )
    )
  }

  const namespaces = ["common", "api"]

  await instance.init({
    interpolation: {
      prefix: "{",
      suffix: "}"
    },
    // https://www.i18next.com/misc/migration-guide#v20.x.x-to-v21.0.0
    compatibilityJSON: "v3",
    lng: locale,
    resources,
    fallbackLng: i18nConfig.defaultLocale,
    supportedLngs: i18nConfig.locales,
    defaultNS: namespaces[0],
    fallbackNS: namespaces[0],
    ns: namespaces,
    preload: resources ? [] : i18nConfig.locales
  })

  return {
    i18n: instance,
    resources: instance.services.resourceStore.data,
    t: instance.t
  }
}
