import {
  configureStore,
  createListenerMiddleware,
  addListener as addReduxListener,
  removeListener as removeReduxListener,
  TypedAddListener,
  TypedStartListening,
  TypedRemoveListener
} from "@reduxjs/toolkit"
import logger from "redux-logger"
import { combineReducers } from "redux"
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
  useStore as useReduxStore
} from "react-redux"
import { userStateSlice } from "./slices/userStatus"
import { tradeinSlice } from "./slices/tradeinCar"
import { dealSlice } from "./slices/deal"
import { chatSlice } from "./slices/chat"
import { socketioSlice } from "./slices/socketio"
import { notificationSlice } from "./slices/notification"
import { confirmationDialogSlice } from "./slices/confirmationDialog"
import { createSocketioMiddleware } from "./middleware/socketio"
import { isBrowser } from "../src/utils"
import { clientInstanceId } from "../src/clientInstanceId"
import type { BrowserConfig } from "../src/browserConfig"
import { ApiInstance, api } from "../src/api"
import { createPushNotifications } from "../src/push-notifications/instance"
import { PushNotifications } from "../src/push-notifications/PushNotifications"
import { getClientEventRecorder } from "../lib/client-event-recorder"

const rootReducer = combineReducers({
  [userStateSlice.name]: userStateSlice.reducer,
  [tradeinSlice.name]: tradeinSlice.reducer,
  [dealSlice.name]: dealSlice.reducer,
  [chatSlice.name]: chatSlice.reducer,
  [notificationSlice.name]: notificationSlice.reducer,
  [confirmationDialogSlice.name]: confirmationDialogSlice.reducer,
  [socketioSlice.name]: socketioSlice.reducer
})

let extra: ExtraDeps = {} as any

const getExtraDeps = () => extra

const listenerMiddleware = createListenerMiddleware({ extra: getExtraDeps })

const createStore = (
  browserConfig: BrowserConfig,
  initialState?: Partial<ReturnType<typeof rootReducer>>
) => {
  extra = {
    api: api(browserConfig.apiBaseUrl),
    pushNotifications: createPushNotifications(browserConfig.pusherInstanceId),
    clientEventRecorder: getClientEventRecorder(browserConfig.apiBaseUrl),
    browserConfig
  }

  const store = configureStore({
    preloadedState: initialState,
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) => {
      let middleware = getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: getExtraDeps
        }
      }).prepend(listenerMiddleware.middleware)

      if (isBrowser) {
        middleware = middleware.prepend(
          createSocketioMiddleware(browserConfig.apiBaseUrl, clientInstanceId)
        ) as any
      }

      if (process.env.DEBUG === "true") {
        return middleware.concat(logger)
      }

      return middleware
    }
  })

  return {
    ...store,
    extra: getExtraDeps
  }
}

export type AppStore = ReturnType<typeof createStore>

export const useDispatch: () => AppDispatch = useReduxDispatch
export const useSelector: TypedUseSelectorHook<AppState> = useReduxSelector
export const useStore: () => AppStore = useReduxStore as any

export type StartListening = TypedStartListening<
  AppState,
  AppDispatch,
  () => ExtraDeps
>

export const startListening =
  listenerMiddleware.startListening as StartListening

export const addListener = addReduxListener as TypedAddListener<
  AppState,
  AppDispatch,
  () => ExtraDeps
>

export const removeListener = removeReduxListener as TypedRemoveListener<
  AppState,
  AppDispatch
>

export type AppState = ReturnType<AppStore["getState"]>
export type AppDispatch = AppStore["dispatch"]

type ExtraDeps = {
  api: ApiInstance
  pushNotifications: PushNotifications
  clientEventRecorder: ReturnType<typeof getClientEventRecorder>
  browserConfig: BrowserConfig
}

export interface ThunkConfig {
  /** return type for `thunkApi.getState` */
  state: AppState
  /** type for `thunkApi.dispatch` */
  dispatch: AppDispatch
  /** type of the `extra` argument for the thunk middleware, which will be passed in as `thunkApi.extra` */
  extra: () => ExtraDeps
  /** type to be passed into `rejectWithValue`'s first argument that will end up on `rejectedAction.payload` */
  rejectValue?: unknown
  /** return type of the `serializeError` option callback */
  serializedErrorType?: unknown
  /** type to be returned from the `getPendingMeta` option callback & merged into `pendingAction.meta` */
  pendingMeta?: unknown
  /** type to be passed into the second argument of `fulfillWithValue` to finally be merged into `fulfilledAction.meta` */
  fulfilledMeta?: unknown
  /** type to be passed into the second argument of `rejectWithValue` to finally be merged into `rejectedAction.meta` */
  rejectedMeta?: unknown
}

export const makeStore = (browserConfig: BrowserConfig) => {
  return createStore(browserConfig)
}
