import { isBrowser } from "../utils"
import { PushNotifications } from "./PushNotifications"
import { Client as PusherBeamsClient } from "@pusher/push-notifications-web"

let pushNotifications: PushNotifications

/**
 * Browser client
 */
export function createPushNotifications(pusherInstanceId: string) {
  if (isBrowser && !pushNotifications) {
    pushNotifications = new PushNotifications({
      createClient: (serviceWorkerRegistration) =>
        new PusherBeamsClient({
          instanceId: pusherInstanceId,
          serviceWorkerRegistration
        })
    })
  }
  return pushNotifications
}
