/**
 * ISO 639-1
 *
 * e.g. "en"
 *
 * https://en.wikipedia.org/wiki/ISO_639-1
 */
exports.LANGUAGE_CODE_SHORT = {
  /** @type {'fi'} */
  FI: 'fi',
  /** @type {'en'} */
  EN: 'en',
  /** @type {'sv'} */
  SV: 'sv'
}

/**
 * ISO 639-2
 *
 * e.g. "eng"
 *
 * https://en.wikipedia.org/wiki/ISO_639-2
 */
exports.LANGUAGE_CODE_LONG = {
  /** @type {'fin'} */
  FIN: 'fin',
  /** @type {'eng'} */
  ENG: 'eng',
  /** @type {'swe'} */
  SWE: 'swe'
}

/**
 * Uppercase ISO 639-2
 *
 * e.g. "ENG"
 *
 * https://en.wikipedia.org/wiki/ISO_639-2
 */
exports.LANGUAGE_CODE_LONG_UPPERCASE = {
  /** @type {'FIN'} */
  FIN: 'FIN',
  /** @type {'ENG'} */
  ENG: 'ENG',
  /** @type {'SWE'} */
  SWE: 'SWE'
}
