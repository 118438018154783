import { useDispatch } from "react-redux"
import {
  NotificationActions,
  NotificationSlice
} from "../store/slices/notification"

export const useNotification = () => {
  const dispatch = useDispatch()

  const displayNotification = (notification: NotificationSlice) => {
    dispatch(NotificationActions.addNotification(notification))
  }

  const clearNotification = () => {
    dispatch(NotificationActions.clearNotification())
  }

  return { displayNotification, clearNotification } as const
}
