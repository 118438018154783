import type { DealJSON } from "@shared/mongodb-schema/lib/sales-platform/schemas/deal/types"
import type { OfferJSON } from "@shared/mongodb-schema/lib/sales-platform/schemas/offer/types"
import {
  addOffer,
  removeOffer,
  subscribeDealUpdates,
  subscribeDealUpdatesFailed,
  subscribeDealUpdatesSuccess,
  unsubscribeDealUpdates
} from "../../../slices/deal"
import { SocketContextConnected } from "../types"

export const handleSubscribeDealUpdates = (
  action: ReturnType<typeof subscribeDealUpdates>,
  context: SocketContextConnected
) => {
  const dealId = action.payload

  context.socket.emit(
    "deal-updates/subscribe",
    { dealId },
    (error: Error | null) => {
      if (error) {
        context.store.dispatch(
          subscribeDealUpdatesFailed({ dealId, error: error.message })
        )
        return
      }

      const eventName = `deal-updates:${dealId}`

      function handler(payload: any) {
        handleDealUpdate(context, payload)
      }

      context.handlers[eventName] = handler

      context.socket.on(eventName, handler)

      context.store.dispatch(subscribeDealUpdatesSuccess(dealId))
    }
  )
}

export const handleUnsubscribeDealUpdates = (
  action: ReturnType<typeof unsubscribeDealUpdates>,
  context: SocketContextConnected
) => {
  const dealId = action.payload
  const eventName = `deal-updates:${dealId}`
  const handler = context.handlers[eventName]

  if (handler) {
    context.socket.off(eventName, handler)
    delete context.handlers[eventName]
  }

  context.socket.emit(`deal-updates/unsubscribe:${dealId}`)
}

function handleDealUpdate(
  context: SocketContextConnected,
  payload: DealUpdatePayload
) {
  if (isOfferCreated(payload)) {
    context.store.dispatch(addOffer(payload.offer))
  }
  if (isOfferRemoved(payload)) {
    context.store.dispatch(removeOffer(payload.offerId))
  }
}

interface DealOfferCreated {
  updateName: string
  dealId: string
  offerId: string
  deal: DealJSON
  offer: OfferJSON
}

interface DealOfferRemoved {
  updateName: string
  dealId: string
  offerId: string
  deal: DealJSON
}

type DealUpdatePayload = DealOfferCreated | DealOfferRemoved

function isOfferCreated(payload: any): payload is DealOfferCreated {
  return payload?.updateName === "OFFER_CREATED"
}

function isOfferRemoved(payload: any): payload is DealOfferRemoved {
  return payload?.updateName === "OFFER_REMOVED"
}
