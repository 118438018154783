const {
  isLocalStorageAccessible,
  readFromLocalStorage,
  writeToLocalStorage,
  removeFromLocalStorage
} = require('../storage/browser-storage')

function EventQueue({
  useLocalStorage = isLocalStorageAccessible(),
  key = 'smilee-ce-persisted',
  capacity
} = {}) {
  let queue = []

  if (useLocalStorage) {
    try {
      queue = JSON.parse(readFromLocalStorage(key)) || []
    } catch (err) {}
  }

  return Object.freeze({
    enqueue,
    flush,
    size
  })

  function enqueue(...items) {
    if (capacity !== undefined) {
      // NOTE: currently queue can go over the capacity,
      // but only when the count of enqueued items is more than the capacity.
      // remove items oldest items until the queue is not full
      while (queue.length > 0 && queue.length + items.length >= capacity) {
        queue.shift()
      }
    }
    queue.push(...items)

    if (useLocalStorage) {
      try {
        writeToLocalStorage(key, JSON.stringify(queue))
      } catch (err) {}
    }
    return true
  }

  /**
   * clears the queue and returns all of the items that were in the queue
   */
  function flush() {
    if (queue.length === 0) return []

    const items = [...queue]
    queue = []

    if (useLocalStorage) {
      removeFromLocalStorage(key)
    }
    return items
  }

  function size() {
    return queue.length
  }
}

module.exports = EventQueue
