const { LANGUAGE_CODE_LONG, LANGUAGE_CODE_SHORT } = require('./constants')

/** @param {string} longCode */
function getShortLanguageCode(longCode) {
  // return input if it's a short language code
  if (LANGUAGE_CODE_SHORT[longCode]) {
    return longCode
  }
  return LONG_CODE_TO_SHORT_CODE[longCode] || LANGUAGE_CODE_SHORT.EN
}

exports.getShortLanguageCode = getShortLanguageCode

/**
 * Long language code is used by our system (e.g. user's language)
 *
 * ISO 639-2
 *
 * e.g. "eng"
 *
 * @param {string} shortCode
 */
function getLongLanguageCode(shortCode) {
  // return input if it's a long language code
  if (LANGUAGE_CODE_LONG[shortCode]) {
    return shortCode
  }
  return SHORT_CODE_TO_LONG_CODE[shortCode] || LANGUAGE_CODE_LONG.ENG
}

exports.getLongLanguageCode = getLongLanguageCode

/**
 * ISO 639-1 to ISO 639-2
 * https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 */
const SHORT_CODE_TO_LONG_CODE = {
  [LANGUAGE_CODE_SHORT.EN]: LANGUAGE_CODE_LONG.ENG,
  [LANGUAGE_CODE_SHORT.FI]: LANGUAGE_CODE_LONG.FIN,
  [LANGUAGE_CODE_SHORT.SV]: LANGUAGE_CODE_LONG.SWE
}

/**
 * ISO 639-2 to ISO 639-1
 * https://en.wikipedia.org/wiki/List_of_ISO_639-2_codes
 */
const LONG_CODE_TO_SHORT_CODE = {
  [LANGUAGE_CODE_LONG.ENG]: LANGUAGE_CODE_SHORT.EN,
  [LANGUAGE_CODE_LONG.FIN]: LANGUAGE_CODE_SHORT.FI,
  [LANGUAGE_CODE_LONG.SWE]: LANGUAGE_CODE_SHORT.SV
}
