import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import type { AppState } from "../store"

export interface ConfirmationDialogSlice {
  id: string | null
  title: string | null
  description: string | null
  handleAccept?: () => void
  handleDecline?: () => void
}

const initialState = (): ConfirmationDialogSlice => ({
  id: null,
  title: null,
  description: null
})

export const confirmationDialogSlice = createSlice({
  name: "confirmationDialog",
  initialState,
  reducers: {
    showConfirmationDialog(
      state,
      action: PayloadAction<{
        id: string
        title: string
        description?: string
        onAccept?: () => void
        onDecline?: () => void
      }>
    ) {
      state.id = action.payload.id
      state.title = action.payload.title
      state.description = action.payload.description ?? null
      state.handleAccept = action.payload.onAccept
      state.handleDecline = action.payload.onDecline
    },
    acceptConfirmation(state, action: PayloadAction<string>) {
      if (state.id === action.payload) {
        return initialState()
      }
    },
    declineConfirmation(state, action: PayloadAction<string>) {
      if (state.id === action.payload) {
        return initialState()
      }
    }
  }
})

const getConfirmationDialogSlice = (state: AppState) => state.confirmationDialog

export const selectConfirmationDialog = createSelector(
  getConfirmationDialogSlice,
  (confirmationDialog) => confirmationDialog
)

export const {
  showConfirmationDialog,
  acceptConfirmation,
  declineConfirmation
} = confirmationDialogSlice.actions

export default confirmationDialogSlice.reducer
