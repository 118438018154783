import { createAsyncThunk } from "@reduxjs/toolkit"
import { ThunkConfig } from "../store"
import type { DeclineDealOfferSuccess } from "../../app/api/offer/[id]/decline/route"
import { notifyApiError } from "../slices/notification"

export const declineDealOffer = createAsyncThunk<
  DeclineDealOfferSuccess["data"],
  { offerId: string; reason: string },
  ThunkConfig
>(
  "deal/decline",
  async ({ offerId, reason }, { dispatch, rejectWithValue, extra }) => {
    const { api } = extra()
    const result = await api.post<{ reason: string }, DeclineDealOfferSuccess>(
      `/api/offer/${offerId}/decline`,
      { reason }
    )

    if (api.isError(result)) {
      dispatch(notifyApiError(result.error))
      return rejectWithValue(result.error)
    }

    return result.data.data
  }
)
