import { useEffect, useState } from "react"
import {
  Box,
  Button,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography
} from "@mui/material"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import styles from "../styles/HomePage.module.css"
import { useDispatch, useSelector } from "../store/store"
import {
  selectIsMustLoginOpen,
  setCookieStatus
} from "../store/slices/userStatus"
import { useMount } from "../hooks/useMount"
import useStorage from "../hooks/useStorage"
import { useTranslation } from "react-i18next"

export interface CookieDialogProps {
  open: boolean
  onClose: () => void
}

function CookieInfo(props: CookieDialogProps) {
  const { t } = useTranslation()

  const { onClose, open } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t("common:used_cookies_title")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            {t("common:cookies_required_title")}:
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <Typography>
            <b>terms</b> - {t("common:cookies_terms_explanation")}
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <Typography>
            <b>appSession</b> - {t("common:cookie_appSession_explanation")}
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <Typography>
            <b>deals-v1</b> - {t("common:cookie_deals_explanation")}
          </Typography>
        </DialogContentText>

        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            {t("common:cookies_analytics_title")}:
          </Typography>
        </DialogContentText>
        <DialogContentText>
          <Typography>
            <b>Google Analytics 4</b> - {t("common:cookies_ga4_explanation")}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t("common:button_close")}</Button>
      </DialogActions>
    </Dialog>
  )
}

function Cookies({ forceOpen = false }) {
  const { t } = useTranslation()
  const isMustLoginOpen = useSelector(selectIsMustLoginOpen)
  const dispatch = useDispatch()
  const [cookieInfoOpen, setCookieInfoOpen] = useState(false)
  const [cookieOpen, setCookieOpen] = useState(forceOpen ?? true)
  const { getItem, setItem } = useStorage()

  const handleAcceptAllCookies = () => {
    // TODO: Add event from cookie consent
    setItem("cookies-allowed", "all", "local")
    dispatch(setCookieStatus("all"))
    setCookieOpen(false)
  }

  const handleAcceptMandatoryCookies = () => {
    // TODO: Add event from cookie consent
    setItem("cookies-allowed", "mandatory", "local")
    dispatch(setCookieStatus("mandatory"))
    setCookieOpen(false)
  }

  const handleClickOpenCookieInfo = () => {
    setCookieInfoOpen(true)
  }

  const handleCloseCookieInfo = () => {
    setCookieInfoOpen(false)
  }

  useEffect(() => {
    if (forceOpen) {
      setCookieOpen(true)
      return
    }
  }, [forceOpen])

  useMount(() => {
    if (
      getItem("cookies-allowed", "local") === "mandatory" ||
      getItem("cookies-allowed", "local") === "all"
    ) {
      setCookieOpen(false)
    }
  })

  if (isMustLoginOpen || !cookieOpen) return null
  return (
    <>
      <Modal disableScrollLock open sx={{ width: "100%" }}>
        <Box className={styles.modal}>
          <AdminPanelSettingsIcon
            color="primary"
            className={styles.modalIcon}
          />
          <Typography variant="h5" gutterBottom>
            {t("common:cookie_settings_title")}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t("common:cookie_settings_text")}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {t("common:cookie_settings_accept_below_text")}
          </Typography>
          <Typography
            sx={{ color: "primary.main" }}
            onClick={handleClickOpenCookieInfo}
            gutterBottom
          >
            {t("common:button_read_more_cookie")}
          </Typography>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            <Button
              variant="outlined"
              sx={{ minWidth: 230, marginBottom: 2, marginTop: 1 }}
              onClick={handleAcceptMandatoryCookies}
              data-testid="accept-mandatory-cookies"
            >
              {t("common:button_continue_with_mandatory_cookies")}
            </Button>
            <Button
              variant="contained"
              sx={{ minWidth: 230 }}
              onClick={handleAcceptAllCookies}
              data-testid="accept-all-cookies"
            >
              {t("common:button_accept_all_cookies")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <CookieInfo open={cookieInfoOpen} onClose={handleCloseCookieInfo} />
    </>
  )
}

export default Cookies
