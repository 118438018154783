import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
  useContext,
  createContext,
  useMemo
} from "react"

/**
 * The user claims returned from the {@link useUser} hook.
 *
 * @category Client
 */
export interface UserProfile {
  sub: string
  name: string
}

export type UserContextValue = {
  user?: UserProfile | null
  error?: Error
  isLoading: boolean
  checkSession: () => Promise<void>
}

/**
 * The error thrown by the default {@link UserFetcher}.
 *
 * The `status` property contains the status code of the response. It is `0` when the request
 * fails, for example due to being offline.
 *
 * This error is not thrown when the status code of the response is `204`, because that means the
 * user is not authenticated.
 *
 * @category Client
 */
export class RequestError extends Error {
  public status: number

  constructor(status: number) {
    /* c8 ignore next */
    super()
    this.status = status
    Object.setPrototypeOf(this, RequestError.prototype)
  }
}

/**
 * Fetches the user from the profile API route to fill the {@link useUser} hook with the
 * {@link UserProfile} object.
 *
 * If needed, you can pass a custom fetcher to the {@link UserProvider} component via the
 * {@link UserProviderProps.fetcher} prop.
 *
 * @throws {@link RequestError}
 */
type UserFetcher = (url: string) => Promise<UserProfile | undefined>

/**
 * Configure the {@link UserProvider} component.
 *
 * If you have any server-side rendered pages (using `getServerSideProps`), you should get the
 * user from the server-side session and pass it to the `<UserProvider>` component via the `user`
 * prop. This will prefill the {@link useUser} hook with the {@link UserProfile} object.
 * For example:
 *
 * In client-side rendered pages, the {@link useUser} hook uses a {@link UserFetcher} to fetch the
 * user from the profile API route. If needed, you can specify a custom fetcher here in the
 * `fetcher` option.
 *
 * **IMPORTANT** If you have used a custom url for your {@link HandleProfile} API route handler
 * (the default is `/api/auth/me`) then you need to specify it here in the `profileUrl` option.
 *
 * @category Client
 */
export type UserProviderProps = React.PropsWithChildren<{
  user: UserProfile | null
  profileUrl?: string
  fetcher?: UserFetcher
}>

/**
 * @ignore
 */
const missingUserProvider = "You forgot to wrap your app in <UserProvider>"

/**
 * @ignore
 */
export const UserContext = createContext<UserContextValue>({
  get user(): never {
    throw new Error(missingUserProvider)
  },
  get error(): never {
    throw new Error(missingUserProvider)
  },
  get isLoading(): never {
    throw new Error(missingUserProvider)
  },
  checkSession: (): never => {
    throw new Error(missingUserProvider)
  }
})

/**
 * @ignore
 */
export type UseUser = () => UserContextValue

export const useUser: UseUser = () => useContext<UserContextValue>(UserContext)

export type UserProvider = (
  props: UserProviderProps
) => ReactElement<UserContextValue>

type UserProviderState = {
  user: UserProfile | null
  error?: Error
  isLoading: boolean
}

const userFetcher: UserFetcher = async (url) => {
  let response
  try {
    response = await fetch(url)
  } catch {
    throw new RequestError(0) // Network error
  }
  if (response.status == 204) return undefined
  if (response.ok) return response.json()
  throw new RequestError(response.status)
}

export const UserProvider = ({
  children,
  user: initialUser,
  profileUrl = process.env.NEXT_PUBLIC_AUTH0_PROFILE || "/api/auth/me",
  fetcher = userFetcher
}: UserProviderProps): ReactElement<UserContextValue> => {
  const [state, setState] = useState<UserProviderState>({
    user: initialUser,
    isLoading: !initialUser
  })

  const checkSession = useCallback(async (): Promise<void> => {
    try {
      const user = await fetcher(profileUrl)
      setState((previous) => ({
        ...previous,
        user: user ?? null,
        error: undefined
      }))
    } catch (error) {
      setState((previous) => ({ ...previous, error: error as Error }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileUrl])

  useEffect((): void => {
    if (state.user) return
    ;(async (): Promise<void> => {
      await checkSession()
      setState((previous) => ({ ...previous, isLoading: false }))
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user])

  const { user, error, isLoading } = state
  const value = useMemo(
    () => ({ user, error, isLoading, checkSession }),
    [user, error, isLoading, checkSession]
  )

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
