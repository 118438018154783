export interface ApiError {
  message: string
  data: {
    sId?: string
  }
}

type ErrorResult = {
  error: ApiError
}

type SuccessResult<Data = unknown> = {
  data: Data
}

export function api(baseUrl: string) {
  return {
    async get<Data = unknown>(path = "/"): Promise<ApiResponse<Data>> {
      const res = await fetch(`${baseUrl}${path}`, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })

      if (!res.ok) {
        return handleErrorResponse<Data>(res)
      }

      if (res.headers.get("content-type")?.startsWith("application/json")) {
        const body = await res.json()
        return {
          data: body
        } as ApiResponse<Data>
      }

      return {
        data: null
      } as ApiResponse<Data>
    },

    async postForm<Data = unknown>(
      path = "/",
      formData: FormData
    ): Promise<ApiResponse<Data>> {
      const res = await fetch(`${baseUrl}${path}`, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json"
        }
      })

      if (!res.ok) {
        return handleErrorResponse<Data>(res)
      }

      if (res.headers.get("content-type")?.startsWith("application/json")) {
        const body = await res.json()
        return {
          data: body
        } as ApiResponse<Data>
      }

      return {
        data: null
      } as ApiResponse<Data>
    },

    async post<
      ReqBody extends Record<string, any> | undefined = undefined,
      Data = unknown
    >(path = "/", reqBody?: ReqBody): Promise<ApiResponse<Data>> {
      const res = await fetch(`${baseUrl}${path}`, {
        method: "POST",
        body: reqBody ? JSON.stringify(reqBody) : undefined,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })

      if (!res.ok) {
        return handleErrorResponse<Data>(res)
      }

      if (res.headers.get("content-type")?.startsWith("application/json")) {
        const body = await res.json()
        return {
          data: body
        } as ApiResponse<Data>
      }

      return {
        data: null
      } as ApiResponse<Data>
    },

    async put<
      ReqBody extends Record<string, any> | undefined = undefined,
      Data = unknown
    >(path = "/", reqBody?: ReqBody): Promise<ApiResponse<Data>> {
      const res = await fetch(`${baseUrl}${path}`, {
        method: "PUT",
        body: reqBody ? JSON.stringify(reqBody) : undefined,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })

      if (!res.ok) {
        return handleErrorResponse(res)
      }

      if (res.headers.get("content-type")?.startsWith("application/json")) {
        const body = await res.json()
        return {
          data: body
        } as ApiResponse<Data>
      }

      return {
        data: null
      } as ApiResponse<Data>
    },

    async del<
      ReqBody extends Record<string, any> | undefined = undefined,
      Data = unknown
    >(path = "/", reqBody?: ReqBody): Promise<ApiResponse<Data>> {
      const res = await fetch(`${baseUrl}${path}`, {
        method: "DELETE",
        body: reqBody ? JSON.stringify(reqBody) : undefined,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })

      if (!res.ok) {
        return handleErrorResponse(res)
      }

      if (res.headers.get("content-type")?.startsWith("application/json")) {
        const body = await res.json()
        return {
          data: body
        } as ApiResponse<Data>
      }

      return {
        data: null
      } as ApiResponse<Data>
    },

    isError<Data>(result: ApiResponse<Data>): result is ErrorResult {
      return "error" in result
    },

    isSuccess<Data>(result: ApiResponse<Data>): result is SuccessResult<Data> {
      return "data" in result
    }
  }
}

async function handleErrorResponse<Data = unknown>(res: Response) {
  if (res.headers.get("content-type")?.startsWith("application/json")) {
    try {
      const body = await res.json()
      if (body) {
        return {
          error: body
        } as ApiResponse<Data>
      }
      // eslint-disable-next-line no-empty
    } catch (err) {
      console.error("failed to parse API error response body: ", err)
    }
  }

  return {
    error: {
      message: "Unexpected error"
    }
  } as ApiResponse<Data>
}

type ApiResponse<Data = unknown> = SuccessResult<Data> | ErrorResult

export type ApiInstance = ReturnType<typeof api>
