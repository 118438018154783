import Image from "next/image"
import { selectPlatformSettings } from "../store/slices/deal"
import { useSelector } from "../store/store"
import SmileeLogo from "../public/smilee-logo-bw.png"

export const HeaderLogo: React.FC = () => {
  const platform = useSelector(selectPlatformSettings)

  if (platform?.logoUrl) {
    return (
      <Image
        src={platform.logoUrl}
        alt="Header logo"
        width={128}
        height={65}
        style={{ objectFit: "contain" }}
      />
    )
  }

  return (
    <Image
      // TODO switch to Smilee logo here when LTR is set up as platform with API key in script, in prod
      src={SmileeLogo}
      alt="Header logo"
      width={128}
      height={65}
      style={{ objectFit: "contain" }}
    />
  )
}
