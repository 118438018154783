import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit"
import { sortAscWith } from "@shared/utils/sort"
import type { DealEventMessageJSON } from "@shared/mongodb-schema/lib/sales-platform/schemas/dealEvent/types"
import type { AppState } from "../store"

export interface ChatMessage extends DealEventMessageJSON {
  isRead: boolean
}

interface ChatSlice {
  messages: DealEventMessageJSON[]
  unreadCount: number
  unreadMessages: Record<string, true>
}

const initialState: ChatSlice = {
  messages: [],
  unreadCount: 0,
  unreadMessages: {}
}

export const sendChatMessage = createAction<string>("chat/sendMessage")
export const sendChatMessageSuccess = createAction<{
  dealId: string
  message: DealEventMessageJSON
}>("chat/sendMessageSuccess")
export const sendChatMessageFailed = createAction<{
  dealId: string
  error: string
}>("chat/sendMessageFailed")

export const markChatMessagesAsReadDebounced = createAction(
  "chat/markChatMessagesAsReadDebounced"
)
export const markChatMessagesAsRead = createAction<{
  dealId: string
}>("chat/markChatMessagesAsRead")
export const markChatMessagesAsReadSuccess = createAction<{
  dealId: string
}>("chat/markChatMessagesAsReadSuccess")
export const markChatMessagesAsReadFailed = createAction<{
  dealId: string
  error: string
}>("chat/markChatMessagesAsReadFailed")

export const subscribeChatMessages = createAction<string>(
  "chat/subscribeChatMessages"
)
export const subscribeChatMessagesSuccess = createAction<string>(
  "chat/subscribeChatMessagesSuccess"
)
export const subscribeChatMessagesFailed = createAction<{
  dealId: string
  error: string
}>("chat/subscribeChatMessagesFailed")

export const unsubscribeChatMessages = createAction<string>(
  "chat/unsubscribeChatMessages"
)

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChatMessages(state, action: PayloadAction<DealEventMessageJSON[]>) {
      state.messages = action.payload
    },
    setReadMessages(
      state,
      action: PayloadAction<{
        unreadCount: number
        unreadMessages: Record<string, true>
      }>
    ) {
      state.unreadCount = action.payload.unreadCount
      state.unreadMessages = action.payload.unreadMessages
    },
    addUnreadMessage(state, action: PayloadAction<string>) {
      state.unreadCount += 1
      state.unreadMessages[action.payload] = true
    },
    addChatMessages(state, action: PayloadAction<DealEventMessageJSON[]>) {
      handleAddMessages(state, action.payload)
    }
  },
  extraReducers(builder) {
    builder.addCase(sendChatMessageSuccess, (state, action) => {
      handleAddMessages(state, [action.payload.message])
    })

    builder.addCase(markChatMessagesAsReadSuccess, (state) => {
      state.unreadCount = 0
      state.unreadMessages = {}
    })
  }
})

function handleAddMessages(state: ChatSlice, messages: DealEventMessageJSON[]) {
  const newMessages = messages.filter((newMessage) =>
    state.messages.every((m) => m._id !== newMessage._id)
  )

  if (newMessages.length > 0) {
    state.messages.push(...newMessages)
  }

  state.messages.sort(
    sortAscWith((m: DealEventMessageJSON) => new Date(m.createdAt).valueOf())
  )
}

export const {
  setChatMessages,
  setReadMessages,
  addUnreadMessage,
  addChatMessages
} = chatSlice.actions

const getChatSlice = (state: AppState) => state.chat

export const selectChatMessages = createSelector(
  getChatSlice,
  (chat) => chat.messages
)

export const selectUnreadMessagesCount = createSelector(
  getChatSlice,
  (chat) => chat.unreadCount
)

export const selectUnreadMessages = createSelector(
  getChatSlice,
  (chat) => chat.unreadMessages
)

export const selectChatMessageList = createSelector(
  selectChatMessages,
  selectUnreadMessages,
  (messages, unreadMessages) =>
    messages.map(
      (message) =>
        ({
          ...message,
          isRead: unreadMessages[message._id] !== true
        } as ChatMessage)
    )
)

export default chatSlice.reducer
