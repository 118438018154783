"use client"
import { UserProfile, UserProvider, useUser } from "../hooks/useUser"
import { StoreProvider } from "../store/StoreProvider"
import { ThemeProvider } from "./ThemeProvider"
import { CssBaseline } from "@mui/material"
import { SocketioProvider } from "./SocketioProvider"
import { ConfirmationDialog } from "./ConfirmationDialog"
import { useTranslation } from "react-i18next"
import { useDispatch } from "../store/store"
import { useEffect } from "react"
import {
  authenticated,
  setLocale,
  setUserId,
  unauthenticated
} from "../store/slices/userStatus"
import { useDebouncedCallback } from "use-debounce"
import dayjs from "dayjs"
import { AppRouterCacheProvider } from "@mui/material-nextjs/v14-appRouter"
import type { BrowserConfig } from "../src/browserConfig"

type Props = {
  authUser: UserProfile | null
  browserConfig: BrowserConfig
}

export const Providers: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  authUser,
  browserConfig
}) => {
  useDayjsLanguage()

  return (
    <AppRouterCacheProvider options={{ enableCssLayer: true }}>
      <UserProvider user={authUser}>
        <StoreProvider browserConfig={browserConfig}>
          <ThemeProvider>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            {children}
            <SocketioProvider />
            <LocaleToStoreProvider />
            <AuthStatusProvider />
            <ConfirmationDialog />
          </ThemeProvider>
        </StoreProvider>
      </UserProvider>
    </AppRouterCacheProvider>
  )
}

export const LocaleToStoreProvider: React.FC = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setLocale(i18n.language))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  return null
}

const AuthStatusProviderInner: React.FC = () => {
  const { user } = useUser()
  const dispatch = useDispatch()

  // prevent double dispatch in React Strict Mode (dev)
  const handleAuthenticated = useDebouncedCallback((user) => {
    const userId = (user && user?.sub?.split("|")[1]) || null
    dispatch(setUserId(userId))
    dispatch(authenticated())
  }, 10)
  // prevent double dispatch in React Strict Mode (dev)
  const handleUnauthenticated = useDebouncedCallback(() => {
    dispatch(setUserId(null))
    dispatch(unauthenticated())
  }, 10)

  useEffect(() => {
    if (user) {
      handleAuthenticated(user)
    } else {
      handleUnauthenticated()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return null
}

export const AuthStatusProvider: React.FC = () => {
  const { isLoading } = useUser()
  return isLoading ? null : <AuthStatusProviderInner />
}

/**
 * Update dayjs language based on the active i18n language
 */
export const useDayjsLanguage = () => {
  const { i18n } = useTranslation()

  const language = i18n.language

  useEffect(() => {
    import(`dayjs/locale/${language}.js`)
      .then(() => dayjs.locale(language))
      .catch((err) =>
        console.info(`Failed to load "${language}" language for dates: `, err)
      )
  }, [language])
}
