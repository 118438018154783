import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography
} from "@mui/material"
import { useTranslation } from "react-i18next"

export interface PrivacyPolicyProps {
  open: boolean
  onClose: () => void
}

function ConsentInfo(props: PrivacyPolicyProps) {
  const { t } = useTranslation()
  const { onClose, open } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t("common:privacy_statement")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>{t("common:privacy_statement_part_1")}</Typography>
        </DialogContentText>

        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            {t("common:privacy_statement_personal_title")}
          </Typography>
          <Typography>{t("common:privacy_statement_personal_text")}</Typography>
        </DialogContentText>

        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            {t("common:privacy_statement_forwarding_title")}
          </Typography>
          <Typography>{t("common:privacy_statement_ltr_text")}</Typography>
        </DialogContentText>

        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            {t("common:privacy_statement_personal_protection_title")}
          </Typography>
          <Typography>
            {t("common:privacy_statement_personal_protection_text")}
          </Typography>
        </DialogContentText>

        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            {t("common:privacy_statement_rights_title")}
          </Typography>
          <Typography>{t("common:privacy_statment_rights_text_1")}</Typography>
          <Typography>{t("common:privacy_statment_rights_text_2")}</Typography>
        </DialogContentText>

        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            {t("common:privacy_statement_save_length_title")}
          </Typography>
          <Typography>
            {t("common:privacy_statement_save_length_text")}
          </Typography>
        </DialogContentText>

        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            {t("common:privacy_statement_contact_title")}
          </Typography>
          <Typography>{t("privacy_statement_contact_text_1")}</Typography>
          <Typography>
            {t("common:privacy_statement_contact_text_2")}
          </Typography>
        </DialogContentText>

        <DialogContentText>
          <Typography variant="h6" gutterBottom>
            {t("common:privacy_statement_changes_title")}
          </Typography>
          <Typography>{t("common:privacy_statement_changes_text")}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {t("common:button_close")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConsentInfo
